import Styles from './cookies.module.css';

/**
 * Chrome has limited max-age of cookies to be 400 days, which means that we should refresh
 * existing cookies with a new max-age. I chose to update cookies on each page load
 * see more info: https://developer.chrome.com/blog/cookie-max-age-expires/
 */
function refreshCookies(domain: string, maxAge: string) {
  const cc = getCookie('consent');
  const ch = getCookie('preferredCookieHash');
  const ch1 = getCookie('analyticsCookieHash');

  if (cc) document.cookie = `consent=${cc}; domain=${domain}; max-age=${maxAge}; path=/;`;
  if (ch) document.cookie = `preferredCookieHash=${ch}; domain=${domain}; max-age=${maxAge}; path=/;`;
  if (ch1) document.cookie = `analyticsCookieHash=${ch1}; domain=${domain}; max-age=${maxAge}; path=/;`;
}

//Returns a hash code from a string.
function hash(str: string) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function getCookie(name: string) {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(name + '='))
    ?.split('=')[1];
}

function wipeCookies(cookieDomain: string) {
  document.cookie = `consent=; domain=${cookieDomain}; path=/; Max-Age=0;`;
  document.cookie = `preferredCookieHash=; domain=${cookieDomain}; path=/; Max-Age=0;`;
  document.cookie = `analyticsCookieHash=; domain=${cookieDomain}; path=/; Max-Age=0;`;
}

// Max cookie age is 400 days as per Chrome limitation
const maxAgeInSeconds = 400 * 24 * 60 * 60;
const defaultCookieDomain = 'empathicbuilding.com';

// Used when disptatching custom events
const customEventOpts = { bubbles: true, cancellable: true, composed: false };

/**
 * Create the consent banner elements and attach them to the DOM
 * By default the elements are hidden in order to give users of the script the
 * ability to customize the styling before showing the banner to end-users.
 *
 * Returns true if DOM elements have been created
 * Returns false if no DOM elements have been created
 */
export function checkConsentAndCreateHiddenBanner() {
  // Assumption is that cookies should be stored to empathicbuilding.com domain no
  // matter from where xxx.empathicbuilding.com subdomain they are actually set from.
  // The exception here is when client is ran locally from e.g. localhost or IP.
  const cookieDomain = location.host.includes(defaultCookieDomain) ? defaultCookieDomain : location.hostname;

  // this list of necessary cookies isn't actually used here, just listing them out as documentation
  // const necessaryCookies = ['seenNotifications', 'orgID', 'hostname', 'acks', 'token', 'pusherTransportTLS', 'domain', 'redirectTo', 'cookieHash', 'consent'];
  const preferredCookies = [
    'showTutorial',
    'mui-mode',
    'mui-color-scheme-light',
    'mui-color-scheme-dark',
    'i18nextLng',
    'userLocale',
    'ebLocalStorageVersion',
    'showInstallPopup',
  ];
  const analyticsCookies = ['_pk_ref', '_pk_cvar', '_pk_id', '_pk_ses', 'matomo_ignore', 'matomo_sessid', '_pk_hsr'];

  // Get hash we can use to compare if user has accepted this specific set of cookies
  const newPrefCookieHash = hash(preferredCookies.join()).toString();
  // Get (possibly) saved previous cookie hash for comparing if we should re-ask consent.
  const prevPrefCookieHash = getCookie('preferredCookieHash');
  if (prevPrefCookieHash && newPrefCookieHash !== prevPrefCookieHash) {
    // cookie hash has changed since last time. We need to re-ask for user consent by deleting prev cookies
    wipeCookies(cookieDomain);
  }

  // Same thing as above but for analytics cookies
  const newAnalCookieHash = hash(analyticsCookies.join()).toString();
  const prevAnalCookieHash = getCookie('analyticsCookieHash');
  if (prevAnalCookieHash && newAnalCookieHash !== prevAnalCookieHash) {
    wipeCookies(cookieDomain);
  }

  // Example consent object if all categories in use
  // const consent = {
  //     necessary: true,  // implemented
  //     preferred: false, // implemented
  //     analytics: false, // implemented
  //     marketing: false  // not yet implemented
  // }

  // Get user saved consents
  let givenConsent;
  try {
    const cookieJSON = getCookie('consent');
    if (cookieJSON) {
      givenConsent = JSON.parse(cookieJSON);
    }
  } catch (error) {
    // prob. tried to parse undefined (no cookie)
  }

  if (!givenConsent || !Object.hasOwn(givenConsent, 'preferred') || !Object.hasOwn(givenConsent, 'analytics')) {
    // User has not prev. given or denied consent for any cookies || given consent for preferred cookies.
    // Ask for consent. In the future when adding new categories, e.g. analytics, we can re-prompt users
    // to give consent by adding new or-clause in the form of  '|| !Object.hasOwn(givenConsent,"analytics")'
    createAndHideConsentBanner();
    return true;
  } else {
    // User has expressed cookie consent, don't ask for consent again. Refresh the existing cookies
    refreshCookies(cookieDomain, maxAgeInSeconds);
    return false;
  }

  function createAndHideConsentBanner() {
    function setAndCloseConsent(
      consent = {
        necessary: true,
        preferred: false,
        analytics: false,
      },
    ) {
      document.cookie = `consent=${JSON.stringify(
        consent,
      )}; domain=${cookieDomain}; max-age=${maxAgeInSeconds}; path=/;`;
      // Set preferred cookie hash so we know which cookies user has consented or not consented for
      document.cookie = `preferredCookieHash=${newPrefCookieHash}; domain=${cookieDomain}; max-age=${maxAgeInSeconds}; path=/;`;
      document.cookie = `analyticsCookieHash=${newAnalCookieHash}; domain=${cookieDomain}; max-age=${maxAgeInSeconds}; path=/;`;

      // Handle Matomo cookies
      if (consent.analytics) {
        // Store consent and enable tracking cookies for Matomo
        window._paq.push(['rememberCookieConsentGiven']);
      } else {
        // Remove/don't add consent for Matomo
        window._paq.push(['forgetCookieConsentGiven']);
      }

      // Dispatch cookie consent event
      document.dispatchEvent(
        new CustomEvent('cookieconsentchanged', {
          detail: { consent: consent },
          ...customEventOpts,
        }),
      );

      // Delete banner element and its children from dom-tree and clear event listeners
      const cb = document.getElementById('cb-root-container');
      const acceptSelectedBtn = document.getElementById('cb-accept-selected');
      const acceptBtn = document.getElementById('cb-accept');
      if (acceptSelectedBtn) acceptSelectedBtn.removeEventListener('click', handleSelection);
      if (acceptBtn) acceptBtn.removeEventListener('click', handleAccept);
      if (cb) cb.remove();
    }

    const handleAccept = () => {
      setAndCloseConsent({ necessary: true, preferred: true, analytics: true });
    };

    const handleSelection = () => {
      setAndCloseConsent({
        necessary: true,
        preferred: preferredCheckbox.checked,
        analytics: analyticsCheckbox.checked,
      });
    };

    // Build the necessary HTML elements
    const container = document.createElement('div');
    container.id = 'cb-root-container';
    container.className = Styles.rootContainer;

    const textContainer = document.createElement('div');
    textContainer.id = 'cb-txt-container';
    textContainer.className = Styles.txtContainer;

    const consentHeader = document.createElement('h3');
    consentHeader.id = 'cb-header3';
    consentHeader.className = Styles.consentHeader;
    consentHeader.innerText += 'We use cookies';

    const consentText1 = document.createElement('span');
    consentText1.id = 'cb-txt1';
    consentText1.innerText +=
      'We use cookies to provide you with the service and to remember your preferences, for instance language preference. For more information on the cookies we use, ';

    const cookiePolicyLink = document.createElement('a');
    cookiePolicyLink.id = 'cb-link';
    cookiePolicyLink.innerText += 'see here.';
    cookiePolicyLink.className = Styles.cookiePolicyLink;
    cookiePolicyLink.href = `${location.origin}/cookies`;

    const consentText2 = document.createElement('span');
    consentText2.id = 'cb-txt2';
    consentText2.innerText +=
      ' We ask for your consent to use cookies with the exception of cookies which are necessary for the functioning of our website. If you click “Accept All Cookies”, you accept all cookies set by us.';

    const btnContainer = document.createElement('div');
    btnContainer.id = 'cb-btn-container';
    btnContainer.className = Styles.btnContainer;

    const checkboxContainer = document.createElement('div');
    checkboxContainer.id = 'cb-checkbox-container';
    checkboxContainer.className = Styles.checkboxContainer;

    // const necessarySwitchContainer = document.createElement('div');
    // necessarySwitchContainer.id = 'cb-necessary-container';
    // necessarySwitchContainer.className = 'switchContainer disabled'

    const analyticsSwitchContainer = document.createElement('div');
    analyticsSwitchContainer.id = 'cb-analytics-container';
    analyticsSwitchContainer.className = Styles.switchContainer;

    const preferredSwitchContainer = document.createElement('div');
    preferredSwitchContainer.id = 'cb-preferred-container';
    preferredSwitchContainer.className = Styles.switchContainer;

    // const necessaryCheckbox = document.createElement('input')
    // const necessaryCbSwitch = document.createElement('label')
    // const necessaryCbSlider = document.createElement('span')
    // const necessaryCbLabel = document.createElement('span')

    const analyticsCheckbox = document.createElement('input');
    const analyticsCbSwitch = document.createElement('label');
    const analyticsCbSlider = document.createElement('span');
    const analyticsCbLabel = document.createElement('span');

    const preferredCheckbox = document.createElement('input');
    const preferredCbSwitch = document.createElement('label');
    const preferredCbSlider = document.createElement('span');
    const preferredCbLabel = document.createElement('span');

    // necessaryCbSlider.className = Styles.slider
    analyticsCbSlider.className = Styles.slider;
    preferredCbSlider.className = Styles.slider;

    // necessaryCheckbox.id = 'necessaryCb'
    analyticsCheckbox.id = 'analyticsCb';
    preferredCheckbox.id = 'preferredCb';

    // necessaryCbSwitch.for = 'necessaryCb'

    analyticsCbSwitch.for = 'analyticsCb';
    preferredCbSwitch.for = 'preferredCb';

    // necessaryCbSwitch.className = Styles.switch
    analyticsCbSwitch.className = Styles.switch;
    preferredCbSwitch.className = Styles.switch;

    // necessaryCbLabel.textContent = 'Necessary'
    analyticsCbLabel.textContent = 'Analytics';
    preferredCbLabel.textContent = 'Preferred';

    // necessaryCheckbox.type = 'checkbox'
    analyticsCheckbox.type = 'checkbox';
    preferredCheckbox.type = 'checkbox';

    // necessaryCheckbox.role = 'switch'
    analyticsCheckbox.role = 'switch';
    preferredCheckbox.role = 'switch';

    // necessaryCheckbox.disabled = true
    // necessaryCheckbox.checked = true

    const acceptSelected = document.createElement('button');
    acceptSelected.id = 'cb-accept-selected';
    acceptSelected.innerText += 'Accept Selected Optional Cookies Only';
    acceptSelected.addEventListener('click', handleSelection);
    acceptSelected.className = Styles.acceptSelectedBtn;

    const acceptAll = document.createElement('button');
    acceptAll.id = 'cb-accept';
    acceptAll.className = Styles.acceptAllBtn;
    acceptAll.innerText += 'Accept All Cookies';
    acceptAll.addEventListener('click', handleAccept);

    // append all child elements to their containers
    textContainer.appendChild(consentHeader);
    textContainer.appendChild(consentText1);
    textContainer.appendChild(cookiePolicyLink);
    textContainer.appendChild(consentText2);

    // necessarySwitchContainer.appendChild(necessaryCbLabel)
    // necessaryCbSwitch.appendChild(necessaryCheckbox)
    // necessaryCbSwitch.appendChild(necessaryCbSlider)
    // necessarySwitchContainer.appendChild(necessaryCbSwitch)

    analyticsSwitchContainer.appendChild(analyticsCbLabel);
    analyticsCbSwitch.appendChild(analyticsCheckbox);
    analyticsCbSwitch.appendChild(analyticsCbSlider);
    analyticsSwitchContainer.appendChild(analyticsCbSwitch);

    preferredSwitchContainer.appendChild(preferredCbLabel);
    preferredCbSwitch.appendChild(preferredCheckbox);
    preferredCbSwitch.appendChild(preferredCbSlider);
    preferredSwitchContainer.appendChild(preferredCbSwitch);

    // checkboxContainer.appendChild(necessarySwitchContainer)
    checkboxContainer.appendChild(preferredSwitchContainer);
    checkboxContainer.appendChild(analyticsSwitchContainer);

    btnContainer.appendChild(acceptSelected);
    btnContainer.appendChild(acceptAll);

    container.appendChild(textContainer);
    container.appendChild(checkboxContainer);
    container.appendChild(btnContainer);

    document.body.appendChild(container);

    // Dispatch an event to let users know the DOM elements have been created
    document.dispatchEvent(
      new CustomEvent('cookiebannercreated', {
        detail: { container: container },
        ...customEventOpts,
      }),
    );
  }
}

// Show the previously created consent banner
export function showConsentBanner() {
  const consentContainer = document.getElementById('cb-root-container');
  if (!consentContainer)
    throw new Error(
      "consentContainer element not found from DOM. Make sure you've called checkConsentAndCreateBanner function before calling showConsentBanner.",
    );
  consentContainer.style.display = 'flex';
}

export function revokeGivenConsent() {
  const cookieDomain = location.host.includes(defaultCookieDomain) ? defaultCookieDomain : location.hostname;
  document.cookie = `consent=; domain=${cookieDomain}; path=/; Max-Age=0;`;
  document.cookie = `preferredCookieHash=; domain=${cookieDomain}; path=/; Max-Age=0;`;
  document.cookie = `analyticsCookieHash=; domain=${cookieDomain}; path=/; Max-Age=0;`;

  // Dispatch an event to let users know the DOM elements have been created
  document.dispatchEvent(
    new CustomEvent('cookieconsentchanged', {
      detail: {},
      ...customEventOpts,
    }),
  );
}

// Get the parsed consent object if it exists
export function getGivenConsent() {
  try {
    const cookieJSON = getCookie('consent');
    if (cookieJSON) {
      return JSON.parse(cookieJSON);
    }
  } catch (error) {
    // no cookie exists, return undefined
    return undefined;
  }
}

// For infoscreen to accept cookies automatically
export const handleAcceptCookiesInfoscreen = () => {
  const consent = {
    necessary: true,
    preferred: true,
  };
  const cookieDomain = location.host.includes(defaultCookieDomain) ? defaultCookieDomain : location.hostname;
  const preferredCookies = [
    'showTutorial',
    'mui-mode',
    'mui-color-scheme-light',
    'mui-color-scheme-dark',
    'i18nextLng',
    'userLocale',
    'ebLocalStorageVersion',
    'showInstallPopup',
  ];
  // Set preferred cookie hash so we know which cookies user has consented or not consented for
  const newPrefCookieHash = hash(preferredCookies.join()).toString();

  document.cookie = `consent=${JSON.stringify(consent)}; domain=${cookieDomain}; max-age=${maxAgeInSeconds}; path=/;`;
  document.cookie = `preferredCookieHash=${newPrefCookieHash}; domain=${cookieDomain}; max-age=${maxAgeInSeconds}; path=/;`;

  // Dispatch cookie consent event
  document.dispatchEvent(
    new CustomEvent('cookieconsentchanged', {
      detail: { consent: consent },
      ...customEventOpts,
    }),
  );
};
