import { getConfig } from '@helpers/ebCore';
import { Asset } from '@interfaces/eb/asset';
import { Eb } from '@interfaces/eb/eb';

export const getDurationForAssetType = (eb: Eb, asset: Asset) => {
  switch (asset.type) {
    case 'desk':
      return getConfig<number>(eb, eb.location.getCurrentlyLoaded(), 'bookingSlotDurationForDesk');
    case 'room':
      if (asset.subtype === 'parking') {
        return getConfig<number>(eb, eb.location.getCurrentlyLoaded(), 'bookingSlotDurationForParking');
      }
      return getConfig<number>(eb, eb.location.getCurrentlyLoaded(), 'bookingSlotDurationForRoom');
    default:
      return getConfig<number>(eb, eb.location.getCurrentlyLoaded(), 'bookingSlotDurationForOther');
  }
};
