function injectMedicalEquipmentLayer() {
  if (!eb || !eb._d.map) {
    throw new Error('Can not inject a layer before eb and map is initiated');
  }

  const layerTemplateFromInfoLayer = {
    ...eb._d.map.layers_map.info,
    name: 'medical_equipment',
    title: 'Medical equipment',
    asset_types: ['medical_equipment'],
    search_types: ['medical_equipment'],
  };

  eb._d.map.layers.push(layerTemplateFromInfoLayer);
  eb.map.layer.getAllAsMap().medical_equipment = layerTemplateFromInfoLayer;
}

function injectBedLayer() {
  if (!eb || !eb._d.map) {
    throw new Error('Can not inject a layer before eb and map is initiated');
  }

  const layerTemplateFromInfoLayer = {
    ...eb._d.map.layers_map.info,
    name: 'bed',
    title: 'Bed',
    asset_types: ['bed'],
    search_types: ['bed'],
  };

  eb._d.map.layers.push(layerTemplateFromInfoLayer);
  eb.map.layer.getAllAsMap().bed = layerTemplateFromInfoLayer;
}

function injectWheelchairLayer() {
  if (!eb || !eb._d.map) {
    throw new Error('Can not inject a layer before eb and map is initiated');
  }

  const layerTemplateFromInfoLayer = {
    ...eb._d.map.layers_map.info,
    name: 'wheelchair',
    title: 'Wheelchair',
    asset_types: ['wheelchair'],
    search_types: ['wheelchair'],
  };

  eb._d.map.layers.push(layerTemplateFromInfoLayer);
  eb.map.layer.getAllAsMap().wheelchair = layerTemplateFromInfoLayer;
}

export { injectBedLayer, injectMedicalEquipmentLayer, injectWheelchairLayer };
