import { ComponentType, CSSProperties } from 'react';
import { allColorsType, Color, Height, mainColors, Shadow, Size, Width } from '../types';

export interface WithStyleProps {
  backgroundColor?: allColorsType | CSSProperties['backgroundColor'];
  isShadow?: boolean;
  shadowColor?: Shadow;
  style?: React.CSSProperties;
  className?: string;
  width?: Width;
  height?: Height;
  size?: Size;
  disabled?: boolean;
}

export function withStyleWrapper<T>(WrappedComponent: ComponentType<T>) {
  const WrappedComponentWithStyle = ({
    backgroundColor,
    isShadow = false,
    shadowColor = 'shadow',
    style,
    className = '',
    width,
    height,
    size,
    disabled = false,
    ...props
  }: T & WithStyleProps) => {
    const backgroundsStyle = getBackgroundColor(disabled, backgroundColor);
    const shadowStyle = getShadow(isShadow, shadowColor);
    const sizeStyle = getSize(width, height, size);
    return (
      <WrappedComponent
        style={{ ...style, ...backgroundsStyle, ...shadowStyle, ...sizeStyle }}
        className={className}
        disabled={disabled}
        {...(props as unknown as T)}
      />
    );
  };
  return WrappedComponentWithStyle;
}

function getBackgroundColor(disabled: boolean, backgroundColor?: Color | CSSProperties['backgroundColor']) {
  const style: React.CSSProperties = {};
  const isHexCode = backgroundColor && /^#([0-9a-f]{3}){1,2}$/i.test(backgroundColor);

  if (disabled) {
    style.backgroundColor = 'var(--color-dark-transparent)';
    style.color = 'white';
    style.pointerEvents = 'none';
    return style;
  }

  if (backgroundColor) {
    const mainColor =
      typeof backgroundColor === 'string' && mainColors.find((color) => backgroundColor.includes(color));
    if (mainColor) {
      style.color = `var(--color-${mainColor}-font)`;
    } else {
      style.color = 'white';
    }
    if (backgroundColor === 'transparent') {
      style.backgroundColor = `transparent`;
    } else if (isHexCode) {
      style.backgroundColor = backgroundColor;
    } else style.backgroundColor = `var(--color-${backgroundColor})`;
  }

  return style;
}

function getShadow(isShadow: boolean, shadowColor: Shadow) {
  const style: React.CSSProperties = {};
  if (isShadow) {
    if (shadowColor) style.boxShadow = `4px 4px var(--color-${shadowColor})`;
  }
  return style;
}

function getSize(width?: Width, height?: Height, size?: Size) {
  const style: React.CSSProperties = {};
  if (width) {
    if (width === 'auto') {
      style.width = '100%';
    } else {
      style.width = `var(--content-${width})`;
    }
  }
  if (height) {
    if (height === 'auto') {
      style.height = '100%';
    } else {
      style.height = `var(--content-item-${height})`;
    }
  }
  if (size) {
    if (size === 'auto') {
      style.width = '100%';
      style.height = '100%';
    } else {
      style.width = `var(--content-item-${size})`;
      style.height = `var(--content-item-${size})`;
    }
  }
  return style;
}
