import { IconDesk, IconRoom } from '@ebDesign/components/Icon';
import { RgbString } from '@ebDesign/interfaces/color';
import { getConfig, getCurrentUser } from '@helpers/ebCore';
import { stateHelper } from '@helpers/state';
import { Asset, AssetObjectTypes } from '@interfaces/eb/asset';
import { Desk } from '@interfaces/eb/desk';
import { Eb } from '@interfaces/eb/eb';
import { ColorLegendEntry, Legend } from '@interfaces/eb/legend';
import { Occupancy } from '@interfaces/eb/occupancy';
import { Reservation } from '@interfaces/eb/reservation';
import { ServiceTicket, Ticket } from '@interfaces/eb/ticket';
import { LocalUserAsset, User } from '@interfaces/eb/user';
import { isUserBookingsVisible } from './booking';

export const getIsUserVirtuallyCheckedIn = (eb: Eb): boolean => {
  const timeNow = eb.getNetworkSyncedTime();
  const currentUserLocation = getCurrentUser(eb)?.local_user_asset?.data_provider_most_recent_by_type?.location;
  if (!currentUserLocation?.value) return false;
  return currentUserLocation.subtype === 'manual' && timeNow < currentUserLocation.value.valid_to;
};

export const getIsUserPhysicallyCheckedIn = (eb: Eb): boolean => {
  const timeNow = eb.getNetworkSyncedTime();
  const currentUserLocation = getCurrentUser(eb)?.local_user_asset?.data_provider_most_recent_by_type?.location;
  if (!currentUserLocation?.value) return false;
  return currentUserLocation.subtype !== 'manual' && timeNow < currentUserLocation.value.valid_to;
};

export const getIsUserCheckedIn = (eb: Eb) => {
  const timeNow = eb.getNetworkSyncedTime();
  const userLocation = getCurrentUser(eb).location;
  if (!userLocation) return false;
  if (typeof userLocation === 'string') return false;
  return timeNow < userLocation.valid_to;
};

export const isUserOnline = (eb: Eb, user: User): boolean => {
  const timeNow = eb.getNetworkSyncedTime();
  const currentUserLocation = (user.local_user_asset || user)?.data_provider_most_recent_by_type?.location.value;
  if (!currentUserLocation) return false;
  return timeNow < currentUserLocation.valid_to;
};

export const isCurrentUserInHomeOrg = (eb: Eb) => {
  return !!eb.user.getCurrent().local_user_asset;
};

export const isUserInCurrentLocation = (eb: Eb, user: User) => {
  if (!isUserOnline(eb, user)) return false;

  const sensorLocation = user.local_user_asset.data_provider_most_recent_by_type.location;
  // Check for current location in three different places, because this seems to vary depending on user
  const id1 = sensorLocation?.value?.location_id;
  const id2 = sensorLocation?.location_id;
  const id3 = typeof user.location === 'object' && 'location_id' in user.location && user.location.location_id;
  return (id1 || id2 || id3) === stateHelper.location.current.get(eb).id;
};

export const isFocusable = (eb: Eb, asset: AssetObjectTypes | LocalUserAsset) => {
  return eb.map.view.isAssetFocusable(asset);
};

const defaultColor: RgbString = 'rgba(204, 204, 204, 1)'; // --color-background-dark

export const getDeskStatus = (eb: Eb, asset: Desk, legends: ColorLegendEntry[]) => {
  const colorArray = asset.current_shade || asset.color_usage_and_reservation;
  const color = colorArray && eb.util.ebToCSSColor(colorArray);
  const text = legends.find((col) => col.color === color)?.text;

  return {
    text: text?.toLowerCase(),
    color: color ?? defaultColor,
    inUse: ['reserved', 'in use'].some((str) => text?.includes(str)),
  };
};

export const getRoomStatus = (eb: Eb, asset: Asset, legends: ColorLegendEntry[]) => {
  const colorArray = eb.util.getRoomStatusColor(asset);
  const rgbaColor = eb.util.ebToCSSColor(colorArray);
  const text = legends.find((col) => col.color === rgbaColor)?.text;
  // Set full opacity for pale colors
  const adjustedRgbaColor = rgbaColor.replace(/[^,]+(?=\))/, '1.0');
  return {
    text: text?.toLowerCase(),
    color: isRgbString(adjustedRgbaColor) ? adjustedRgbaColor : rgbaColor,
  };
};

const isRgbString = (str: string): str is RgbString => {
  return str.startsWith('rgb');
};

export const getZoneColor = (text: string, legends: ColorLegendEntry[]) => {
  if (!text) return defaultColor;
  const legendColor = legends.find((legend) => legend.text.toLowerCase() === text.toLowerCase())?.color;
  if (!legendColor) return defaultColor;

  // Set full opacity for pale colors
  const adjustedColor = legendColor.replace(/[^,]+(?=\))/, '1.0');
  if (!isRgbString(adjustedColor)) return defaultColor;

  return adjustedColor;
};

export const getAssetByLocation = (eb: Eb, assetId: string, locationId: string) => {
  return eb.legacy_services.asset.get(assetId, undefined, locationId);
};

export const getOrganizations = (eb: Eb) => {
  return eb.legacy_services.organization.all();
};

export const getLocationSensorByVendorId = (eb: Eb, vendorId: string) => {
  // TODO: vendorid does not guarantee uniqueness, as multiple vendors might use same id
  const sensors = eb.sensor.getAllAsPreSortedMap().by_type.location;
  return sensors?.find((sensor) => sensor.vendor_id === vendorId);
};

export const getLocationSensorById = (eb: Eb, id: number | null) => {
  if (!id) return;
  const sensors = eb.sensor.getAllAsPreSortedMap().by_type.location;
  return sensors?.find((sensor) => sensor.id.toString() === id.toString());
};

export const getTicketAsset = (eb: Eb, text: Ticket['text'], x: number, y: number): ServiceTicket => {
  const asset = {
    text: text,
    type: 'ticket',
    subtype: 'generic',
    resolution_status: 1,
    unpublished: getConfig(eb, eb.organization.getCurrentlyLoaded(), 'ticketsVoicesRequireModeration') as boolean,
    comments: [],
    angles: [0, 0, 0],
    coords: [x, y],
    parent_id: eb.map.view.getCurrentFloor().id,
    history: [],
  };

  return asset;
};

export const getVoiceAsset = (eb: Eb, text: string, feeling: string, x: number, y: number) => {
  const asset = {
    text: text,
    feeling: feeling,
    type: 'voice',
    subtype: 'generic',
    unpublished: getConfig(eb, eb.organization.getCurrentlyLoaded(), 'ticketsVoicesRequireModeration'),
    comments: [],
    angles: [0, 0, 0],
    coords: [x, y],
    parent_id: eb.map.view.getCurrentFloor().id,
    history: [],
  };

  return asset;
};

export const getCurrentReservationInfo = (eb: Eb, asset: Asset, reservedByText: string, unknownText: string) => {
  const reservationInfo = { text: [reservedByText, unknownText], img: '/img/avatar.png' };

  const currentReservation = asset.data_provider_most_recent_by_type?.reservation;
  if (!currentReservation?.current_event) return;

  // check if user id is attached to reserveation
  const reservationUserId = currentReservation.current_event.user_id;
  if (!reservationUserId) return reservationInfo;

  const user = eb.user.getById(reservationUserId);
  if (!user) return;

  if (!isUserBookingsVisible(eb, user)) return reservationInfo;

  if (user.local_user_asset.name) {
    reservationInfo.text = [reservedByText, `${user.local_user_asset.name}`];
  }
  if (user.local_user_asset.texture_path) {
    reservationInfo.img = user.local_user_asset.texture_path;
  }
  return reservationInfo;
};

export const getLayerData = (eb: Eb, layerName: string) => {
  const layer = eb.map.layer.getByName(layerName);
  return layer.legend_func && layer.legend_func(eb);
};

export const getLegendColor = (layerData: Legend, feeling: string) => {
  const legendsData = layerData.datas[1].data;
  const correctFeeling = legendsData.find((object: { text: string; color: string }) =>
    object.text.toLowerCase().includes(feeling),
  );
  if (!correctFeeling) return;

  return correctFeeling.color;
};

export const getUserAssetFromLocalAsset = (eb: Eb, localUserAsset: LocalUserAsset) => {
  return eb.user.getAllAsMap()[localUserAsset.external_id];
};

export const mapFloorIdtoSDKArrayIndex = (eb: Eb, floorId: string) => {
  return eb._d.floor_index_map[Number(floorId)];
};

/**
 * This function is based on guesswork as there is no clear value from SDK side,
 * wether the asset is actually occupied or not. Neither is it documented anywhere.
 * Previous attempts focues to extract the information from color matching the layer.
 *
 * Will return true if asset is currently in use
 *
 */
export const isAssetInUse = (eb: Eb, asset: Asset) => {
  const occupancySensor = asset.data_provider_most_recent_by_type?.occupancy;
  if (!occupancySensor) return false;
  // Still seems to be missmatch with map
  return occupancySensor.value.used !== 0 && occupancySensor.value.valid_to > eb.getNetworkSyncedTime();
};

/**
 * This function is based on guesswork as there is no clear value from SDK side,
 * wether the asset is actually reserver or not. Neither is it documented anywhere.
 * Previous attempts focues to extract the information from color matching the layer.
 *
 * Will return true if asset is currently occupied based on previous confirmed occupancy
 *
 */
export const isDeskOccupied = (eb: Eb, asset: Asset) => {
  const occupancySensor = asset.data_provider_most_recent_by_type?.occupancy;
  if (!occupancySensor) return false;
  const cleanDeskTime = getConfig<number | undefined>(eb, asset, 'cleanDeskPolicy');
  if (typeof cleanDeskTime !== 'undefined') {
    return occupancySensor.last_confirmed_occupation + cleanDeskTime * 60000 > eb.getNetworkSyncedTime();
  }
  return false;
};

export const isSensorOnline = (eb: Eb, sensor: Occupancy | Reservation) => {
  if (!sensor.value?.valid_to) return false;
  return sensor.value.valid_to > eb.getNetworkSyncedTime();
};

export const isDeskOrRoomFree = (eb: Eb, asset: Asset) => {
  if (!['desk', 'room'].includes(asset.type || '')) return false;
  const occupancySensor = asset.data_provider_most_recent_by_type?.occupancy;
  const reservationSensor = asset.data_provider_most_recent_by_type?.reservation;
  switch (asset.type) {
    case 'desk': {
      if (
        occupancySensor &&
        reservationSensor &&
        isSensorOnline(eb, reservationSensor) &&
        isSensorOnline(eb, occupancySensor)
      ) {
        return !(reservationSensor.reservation_active || isAssetInUse(eb, asset) || isDeskOccupied(eb, asset));
      }
      if (reservationSensor && isSensorOnline(eb, reservationSensor)) {
        return !reservationSensor.reservation_active;
      }
      if (occupancySensor && isSensorOnline(eb, occupancySensor)) {
        return !(isAssetInUse(eb, asset) || isDeskOccupied(eb, asset));
      }
      return false;
    }
    case 'room': {
      if (
        occupancySensor &&
        reservationSensor &&
        isSensorOnline(eb, reservationSensor) &&
        isSensorOnline(eb, occupancySensor)
      ) {
        return !(reservationSensor.reservation_active || isAssetInUse(eb, asset));
      }
      if (reservationSensor && isSensorOnline(eb, reservationSensor)) {
        return !reservationSensor.reservation_active;
      }
      if (occupancySensor && isSensorOnline(eb, occupancySensor)) {
        return !isAssetInUse(eb, asset);
      }
      return false;
    }
    default:
      return false;
  }
};

export const getAssetTexture = (asset: Asset) => {
  switch (asset?.type) {
    case 'room':
      return asset.card_image || IconRoom;
    case 'desk':
      return asset.card_image || IconDesk;
    case 'employee':
      return asset.texture_path || '/img/avatar.png';
    default:
      return asset?.texture_path;
  }
};
