import { renderAlert } from '@ebDesign/components/Alert/renderAlert';
import { RgbString } from '@ebDesign/interfaces/color';
import { ExistingBooking } from '@features/AssetCard/booking/AdvancedBooking/reducer/state';
import { getDurationForAssetType } from '@features/AssetCard/booking/AdvancedBooking/util';
import { getConfig } from '@helpers/ebCore';
import { stateHelper } from '@helpers/state';
import { Asset } from '@interfaces/eb/asset';
import { Booking, BookingConfig, BookingSlot, ReservationStatus } from '@interfaces/eb/booking';
import { Eb } from '@interfaces/eb/eb';
import { User } from '@interfaces/eb/user';
import { SDKColorArrayToRGBAString, SDKColorArrayToRGBString } from '@utils/color';
import { DateTime, Interval } from 'luxon';

type CreateBookingSuggestions = {
  start: DateTime;
  end?: DateTime;
  stepLength: number;
  duration: number;
  existingBookings: ExistingBooking[];
};

export const getReservationSensorForAsset = (asset: Asset) => {
  return asset.data_provider_most_recent_by_type?.reservation;
};

export const getOccupancySensorForAsset = (asset: Asset) => {
  return asset.data_provider_most_recent_by_type?.occupancy;
};

// TODO: could be replaced with asset.data_provider_most_recent_by_type?.reservation?.current_event
export const getCurrentReservationFromAsset = (eb: Eb, asset: Asset) => {
  const now = eb.getNetworkSyncedTime();
  return asset.data_provider_most_recent_by_type?.reservation?.value?.events.find(
    (event) => event.start < now && event.end > now,
  );
};

export const getReservationStatusForAsset = (eb: Eb, asset: Asset): ReservationStatus => {
  const event = getCurrentReservationFromAsset(eb, asset);
  const { id } = eb.user.getCurrent();

  if (!event) return 'free';
  if (event.user_id === id) return 'own';
  return 'occupied';
};

export const getAvailableBookingSlots = (eb: Eb, asset: Asset): BookingSlot[] => {
  const reservationStatus = getReservationStatusForAsset(eb, asset);
  if (reservationStatus !== 'free') return [];
  const currentDateTime = DateTime.fromMillis(eb.getNetworkSyncedTime());
  const bookingSlots = [];
  const bookingSlotDuration = getDurationForAssetType(eb, asset);
  const reservation = getReservationSensorForAsset(asset);
  const nextReservation = reservation?.next_event;
  const nextReservationStartTime = nextReservation && DateTime.fromMillis(nextReservation.start);
  const roundedToMinutes = bookingSlotDuration > 30 ? 30 : bookingSlotDuration;
  // init dateTime.now with server synced time
  for (let index = 1; index < 5; index++) {
    const bookingSlotEndTime = currentDateTime.plus({ minutes: index * bookingSlotDuration });
    const minutesRemainder = roundedToMinutes - (bookingSlotEndTime.minute % roundedToMinutes);
    const bookingSlotEndTimeRoundedToNext30min = bookingSlotEndTime
      .set({ second: 0, millisecond: 0 })
      .plus({ minutes: minutesRemainder });

    if (!nextReservationStartTime || bookingSlotEndTimeRoundedToNext30min <= nextReservationStartTime) {
      bookingSlots.push({
        startUnixDate: currentDateTime.set({ second: 0, millisecond: 0 }).toMillis(),
        endUnixDate: bookingSlotEndTimeRoundedToNext30min.toMillis(),
      });
    }
  }
  if (nextReservationStartTime && bookingSlots.length === 0) {
    // Next booking in less than 30 mins. Allow booking for 1-29 minutes
    bookingSlots.push({
      startUnixDate: currentDateTime.set({ second: 0, millisecond: 0 }).toMillis(),
      endUnixDate: nextReservationStartTime.set({ second: 0, millisecond: 0 }).toMillis(),
    });
  }

  return bookingSlots;
};

export const dateDifferenceIndicator = (date: DateTime) => {
  const today = DateTime.now().startOf('day');
  const start = date.startOf('day');
  return start.diff(today, 'day').toObject().days;
};

export const isReservationWithinOneDay = (date: DateTime) => {
  const difference = dateDifferenceIndicator(date);

  //  yesterday = -1, today = 0,  tomorrow = 1
  if (difference === undefined) return false;
  return [-1, 0, 1].includes(difference);
};

export const getExistingBookings = (asset: Asset, eb: Eb, translationContext: string, currentUserId: User['id']) => {
  const events = getReservationSensorForAsset(asset)?.value?.events;
  if (!events) return [];

  const getReservationName = (userId: Booking['user_id'], subject: Booking['subject']) => {
    const user = userId && eb.user.getById(userId);
    const isOwnBooking = user && user.id === currentUserId;
    if (!user && !isOwnBooking) {
      // Individual asset might have own config for hiding subject, e.g. executive meeting rooms
      const isSubjectShown = !getConfig<boolean>(eb, asset, 'isReservationSubjectHidden');
      if (isSubjectShown && subject) {
        return subject;
      }
      return translationContext;
    }
    if (!isUserBookingsVisible(eb, user) && !isOwnBooking) return translationContext;

    return user.local_user_asset.name || user.email_public || translationContext;
  };

  return events.map(({ start, end, subject, user_id }) => {
    return {
      start: DateTime.fromMillis(start),
      end: DateTime.fromMillis(end),
      title: getReservationName(user_id, subject),
    };
  });
};

export const createBookingSuggestions = ({
  start,
  end,
  stepLength,
  duration,
  existingBookings,
}: CreateBookingSuggestions) => {
  const now = DateTime.now();
  const isPast = now.minus({ minute: 15 }) > start;
  if (isPast) return;

  const availableStartTimes = [];
  const availableEndTimes = [];

  let minStartTime = start;
  let maxEndTime = start.set({ hour: 23, minute: 59, second: 59 });
  let suggestedEndTime = end && end > start ? end : start.plus({ minute: duration });

  const bookingsOnTheSelectedDay = existingBookings.filter((booking) => start.hasSame(booking.start, 'day'));
  const selectedInterval = Interval.fromDateTimes(start, suggestedEndTime);
  const midnight = start.set({ hour: 0, minute: 0, second: 0 });
  const lastBooking = bookingsOnTheSelectedDay[bookingsOnTheSelectedDay.length - 1];

  // set minStartTime
  if (bookingsOnTheSelectedDay.length === 0) {
    minStartTime = now < midnight ? midnight : start.set({ hour: now.hour, minute: now.minute, second: now.second });
  } else {
    // check if no prior booking exists from the selected start
    const isNoPriorEvent = Interval.fromDateTimes(midnight, bookingsOnTheSelectedDay[0].start).overlaps(
      selectedInterval,
    );
    if (isNoPriorEvent) {
      minStartTime = midnight;
    }

    // check if no later booking exists from the selected start
    const isNoLaterEvent = Interval.fromDateTimes(lastBooking.end, maxEndTime).overlaps(selectedInterval);
    if (isNoLaterEvent) {
      minStartTime = lastBooking.end;
    }
  }

  for (let i = 0; i < bookingsOnTheSelectedDay.length; i++) {
    const { start: existingStart, end: existingEnd } = bookingsOnTheSelectedDay[i];
    const existingInterval = Interval.fromDateTimes(existingStart, existingEnd);
    const isOverlapping = Interval.fromDateTimes(start, suggestedEndTime).overlaps(existingInterval);

    if (isOverlapping) return;

    if (existingEnd > start) {
      maxEndTime = existingStart;
      if (maxEndTime < suggestedEndTime) {
        suggestedEndTime = maxEndTime;
      }
      if (i > 0) {
        const prevExistingBooking = bookingsOnTheSelectedDay[i - 1];
        minStartTime = prevExistingBooking.end;
      }
      break;
    }
  }

  const availableTimeDurationForStart = maxEndTime.diff(minStartTime, 'minute');
  const roundedMinStartTime =
    minStartTime.get('minute') % stepLength === 0 ? minStartTime : minStartTime.plus({ minute: 15 }).startOf('hour');
  const startingSlots = availableTimeDurationForStart.get('minute') / stepLength;

  for (let i = 0; i < startingSlots; i++) {
    const timeslot = start.set({ hour: roundedMinStartTime.get('hour'), minute: roundedMinStartTime.get('minute') });
    let endTimeSlot = timeslot.plus({ minute: (i + 1) * stepLength });
    const startTimeSlot = timeslot.plus({ minute: i * stepLength });

    if (startTimeSlot.get('day') !== start.get('day')) break;

    availableStartTimes.push(startTimeSlot);

    if (start < endTimeSlot) {
      if (endTimeSlot.get('hour') === 0 && endTimeSlot.get('minute') === 0) {
        endTimeSlot = endTimeSlot.minus({ second: 1 });
      }
      availableEndTimes.push(endTimeSlot);
    }
  }

  return {
    availableStartTimes,
    availableEndTimes,
    startTime: start,
    suggestedEndTime,
  };
};

export const cancelBooking = async (
  eb: Eb,
  asset: Asset,
  event: Booking,
  isLoading: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  t: any,
) => {
  if (isLoading) return;

  const sensor = asset.data_provider_most_recent_by_type?.reservation;
  if (!sensor) return;
  const { vendor, vendor_id } = sensor;

  setIsLoading(true);
  try {
    await eb.legacy_services.booking.remove({
      location_id: asset.location_id,
      vendor_id,
      vendor,
      booking_ref: event.booking_ref,
    });
  } catch (error) {
    let originalErrorMessage = '';
    if (error instanceof Response) {
      originalErrorMessage = await error.text();
    }
    renderAlert({
      variant: 'error',
      message: `${t(`card.bookingSection.reservationBanner.cancelError`)} ${originalErrorMessage}`,
      time: 8000,
      ariaLabelClose: t('generic.close'),
    });
    setIsLoading(false);
  }
};

export const missingSensorTranslation = 'alertMessage.booking.assetMissingSensor';
export const bookAsset = async (eb: Eb, asset: Asset, bookingInterval: Interval) => {
  const reservationDp = asset.data_provider_most_recent_by_type?.reservation;
  if (!reservationDp) return Promise.resolve(missingSensorTranslation);
  const { vendor, vendor_id } = reservationDp;

  const start = bookingInterval.start.toMillis();
  const end = bookingInterval.end.toMillis();
  return await eb.legacy_services.booking.create({
    location_id: asset.location_id || stateHelper.location.current.get(eb).id,
    vendor_id,
    vendor,
    from: start,
    to: end,
  });
};

export const isUserBookingsVisible = (eb: Eb, user?: User) => {
  if (!user) return false;
  const bookingConf = getConfig<BookingConfig>(eb, eb.organization.getCurrentlyLoaded(), 'booking');
  const isUserBookingsOptOut = Boolean(bookingConf?.showUserBookings);
  const isUserBookingsForcedVisible = Boolean(bookingConf?.forceShowUserBookings);
  const hasUserOptedIn = user.avatar_booking_visible;

  return isUserBookingsForcedVisible || (hasUserOptedIn ?? isUserBookingsOptOut);
};

export const getEarliestOngoingOrUpcomingBooking = (asset: Asset, beforeDateTime?: DateTime): undefined | Booking => {
  const events = asset.data_provider_most_recent_by_type?.reservation?.value?.events;
  if (!events || !events.length) return;
  // Events are always sorted so that the first index is the current ongoing or the next upcoming
  const ongoingOrNextEvent = events[0];
  if (beforeDateTime) {
    return ongoingOrNextEvent.start < beforeDateTime.toMillis() ? ongoingOrNextEvent : undefined;
  }
  return ongoingOrNextEvent;
};

export const getGenericBookingInfoFromAsset = (eb: Eb, asset: Asset, format = 'rgb') => {
  const event = getCurrentReservationFromAsset(eb, asset);
  // TODO: once we have agreed on a way of storing general reservation colors use them instead, for now use room
  const colorArray = getConfig<number[][]>(eb, asset, 'roomUsageStatusColors');
  if (format === 'rgba') {
    return {
      status: event ? 'reserved' : 'free',
      color: event ? SDKColorArrayToRGBAString(colorArray[2]) : SDKColorArrayToRGBAString(colorArray[0]),
    };
  }
  return {
    status: event ? 'reserved' : 'free',
    color: (event ? SDKColorArrayToRGBString(colorArray[2]) : SDKColorArrayToRGBString(colorArray[0])) as RgbString,
  };
};
