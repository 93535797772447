import { SVGProps } from 'react';
const SvgEbExclamation = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <path
      d="m147.7 39.35-13.37 108.04c0 9.85-7.98 17.83-17.83 17.83h-6.54V77.09c0-20.84 16.9-37.74 37.74-37.74"
      stroke={props.color}
      strokeMiterlimit={10}
      strokeWidth={0.2}
      fill={props.color}
    />
    <path
      d="M121.8 216.75c-7.5 0-13.6-6.1-13.6-13.6s6.1-13.6 13.6-13.6 13.61 6.1 13.61 13.6-6.1 13.6-13.61 13.6Z"
      fill={props.color}
    />
    <path
      d="M121.8 189.64c7.45 0 13.51 6.06 13.51 13.5s-6.06 13.5-13.51 13.5-13.5-6.06-13.5-13.5 6.06-13.5 13.5-13.5m0-.2c-7.56 0-13.7 6.15-13.7 13.7s6.15 13.7 13.7 13.7 13.71-6.15 13.71-13.7-6.15-13.7-13.71-13.7Z"
      fill={props.color}
    />
  </svg>
);
export default SvgEbExclamation;
