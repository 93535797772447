/**
 * FILE DOCS
 *
 * Define functions that returns an object with a get and and onUpdate field.
 * get:  defines the function which returns a value from EB object.
 * onUpdate: defines a function that takes a callback, which is invoked when the associated
 * events are triggered. Returns an array of hook handles, which can be used to unmounting.
 *
 * Example function
 *
 * const getBananaFromEb = () => {
 *   return {
 *     get: (eb: Eb) => eb.banana,
 *     onUpdate: (eb: Eb, setBanana) => [
 *       eb.addHook(CORE_EVENTS.POST_BANANA_UPDATE, setBanana)
 *     ]
 *   }
 * }
 */

import { Asset, AssetObjectTypes, ValidAssetStringTypes } from '@interfaces/eb/asset';
import { Building } from '@interfaces/eb/building';
import { Dataprovider } from '@interfaces/eb/dataprovider';
import { Eb } from '@interfaces/eb/eb';
import { Floor } from '@interfaces/eb/floor';
import {
  hookCallback,
  post_asset_dp_update_callback,
  post_floor_change_event_callback,
  post_location_load_event_callback,
  post_location_update_event_callback,
  post_location_update_event_filter,
  post_notification_update_event_callback,
  post_user_update,
} from '@interfaces/eb/hook';
import { Layer } from '@interfaces/eb/layer';
import { mapFloorIdtoSDKArrayIndex } from './eb';
import { CORE_EVENTS, MAP_EVENTS } from './events';

const currentLocation = () => {
  const filterLocationUpdate: post_location_update_event_filter = (eb, locationUpdates) => {
    const currentLocationId = eb.location.getCurrentlyLoaded().id;
    if (!currentLocationId) return false;
    return locationUpdates.some((locationUpdate) => locationUpdate.id === currentLocationId);
  };
  return {
    get: (eb: Eb) => eb.location.getCurrentlyLoaded(),
    onUpdate: (eb: Eb, callback: hookCallback) => [
      eb.addHook(CORE_EVENTS.POST_LOCATION_LOAD, callback),
      eb.addHook(CORE_EVENTS.POST_LOCATION_UPDATE, callback, filterLocationUpdate),
    ],
  };
};

const locationById = () => {
  return {
    get: (eb: Eb, id: string) => eb.location.getAllAsList().find((location) => location.id === id),
    // TODO: onUpdate: where the locationId is filtered
  };
};

const allLocationsInCurrentOrganisation = () => {
  return {
    get: (eb: Eb) => eb.location.getAllAsList(),
    onUpdate: (eb: Eb, callback: post_location_update_event_callback) => [
      eb.addHook(CORE_EVENTS.POST_LOCATION_UPDATE, callback),
    ],
  };
};

const currentBuilding = () => {
  // Todo: asset update in building should be added to onUpdate
  return {
    get: (eb: Eb) => eb.map.view.getCurrentBuilding(),
    onUpdate: (eb: Eb, callback: post_floor_change_event_callback) => [
      eb.map.addHook(MAP_EVENTS.POST_FLOOR_CHANGE, callback),
    ],
  };
};

const allBuildingsInOrganisation = () => {
  // Todo: asset update in building should be added to onUpdate
  return {
    get: async (eb: Eb) => {
      const locationAndBuildingIdsList = allLocationsInCurrentOrganisation()
        .get(eb)
        .reduce(
          (acc, cur) => {
            return [...acc, ...cur.buildings.map((building) => ({ locationId: cur.id, buildingId: building }))];
          },
          [] as Array<{ locationId: string; buildingId: string }>,
        );

      const buildings = await Promise.all(
        locationAndBuildingIdsList.map((item) =>
          eb.legacy_services.asset.get(item.buildingId, undefined, item.locationId),
        ),
      );
      return buildings as unknown as Building[];
    },
    //TODO: onUpdate filter assets updates by building ids
  };
};

const allBuildingsInCurrentLocation = () => {
  return {
    get: (eb: Eb) => eb.asset.getAllAsPreSortedMap().by_type.building as unknown as Building[],
    onUpdate: (eb: Eb, callback: post_location_load_event_callback) => [
      eb.addHook(CORE_EVENTS.POST_LOCATION_LOAD, callback),
    ],
  };
};

const currentFloor = () => {
  return {
    get: (eb: Eb) => eb.map.view.getCurrentFloor(),
    onUpdate: (eb: Eb, callback: post_floor_change_event_callback) => [
      eb.map.addHook(MAP_EVENTS.POST_FLOOR_CHANGE, callback),
    ],
  };
};

/**
 * Returns all list of floors. Only buildings in current location allowed.
 */
const getAllFloorsByBuildingId = () => {
  return {
    get: (eb: Eb, buildingId: string) =>
      (eb.asset.getById(buildingId) as unknown as Building).floors
        .map((floorId) => eb.asset.getById(floorId))
        .filter((floor) => floor) as unknown as Floor[],
  };
};

const getAllFloorsInCurrentLocation = () => {
  return {
    get: (eb: Eb) => eb.asset.getAllAsPreSortedMap().by_type.floor,
    onUpdate: (eb: Eb, callback: post_location_load_event_callback) => [
      eb.addHook(CORE_EVENTS.POST_LOCATION_LOAD, callback),
    ],
  };
};

const getFloorByFloorId = () => {
  return {
    get: (eb: Eb, floorId: string) =>
      eb.asset.getAllAsPreSortedMap().by_type.floor.find((floor) => floor.id === floorId),
    onUpdate: (eb: Eb, callback: post_location_load_event_callback) => [
      eb.addHook(CORE_EVENTS.POST_LOCATION_LOAD, callback),
    ],
  };
};

const getAllFloorsInCurrentBuilding = () => {
  return {
    get: (eb: Eb) =>
      currentBuilding()
        .get(eb)
        .floors.map((floorId) => eb.asset.getById(floorId))
        .filter((floor) => floor) as unknown as Floor[],
    onUpdate: (eb: Eb, callback: post_floor_change_event_callback) => [
      eb.map.addHook(MAP_EVENTS.POST_FLOOR_CHANGE, callback),
    ],
  };
};

const allNotifications = () => {
  return {
    get: (eb: Eb) => eb.notification.getAllAsList(),
    onUpdate: (eb: Eb, callback: post_notification_update_event_callback) => [
      eb.addHook(CORE_EVENTS.POST_NOTIFICATION_UPDATE, callback),
    ],
  };
};

const user = () => {
  return {
    current: (eb: Eb) => eb.user.getCurrent(),
    onUpdate: (eb: Eb, callback: post_user_update) => [eb.addHook(CORE_EVENTS.POST_USER_UPDATE, callback)],
  };
};

const currentLocationNotifications = () => {
  return {
    get: (eb: Eb) =>
      eb.notification.getAllAsList().filter((notification) => {
        const hasOrganization = !!notification.organizations.length;
        const hasLocations = !!notification.locations.length;
        const isCurrentLocationIncluded = notification.locations.includes(stateHelper.location.current.get(eb).id);
        const isCurrentOrganizationIncluded = notification.organizations.includes(
          eb.organization.getCurrentlyLoaded().id,
        );
        if (hasLocations) {
          return isCurrentLocationIncluded;
        }
        if (hasOrganization) {
          return isCurrentOrganizationIncluded;
        }
        return true;
      }),
    onUpdate: (eb: Eb, callback: post_notification_update_event_callback) => [
      eb.addHook(CORE_EVENTS.POST_NOTIFICATION_UPDATE, callback),
      eb.addHook(CORE_EVENTS.POST_LOCATION_LOAD, callback),
    ],
  };
};

/**
 * Suitable to get asset so it can be displayd in asset cards, search result, service and voice ticket list.
 * Do not use without registering to the update event to make sure asset is always up to date.
 */
const assetByIdInCurrentLocation = () => {
  const filterAssetDPUpdate = (_eb: Eb, asset: Asset, _dataProvider: Dataprovider, assetId: string) => {
    return assetId === asset.id;
  };
  return {
    get: (eb: Eb, assetId: string) => eb.asset.getById(assetId), //POST_ASSET_DP_UPDATE
    onUpdate: (eb: Eb, assetId: string, callback: post_asset_dp_update_callback) => [
      eb.addHook(CORE_EVENTS.POST_ASSET_DP_UPDATE, callback, (eb: Eb, asset: Asset, dataProvider: Dataprovider) =>
        filterAssetDPUpdate(eb, asset, dataProvider, assetId),
      ),
    ],
  };
};

const assetGetAll = () => {
  const filterAssets = (eb: Eb, filter?: { type?: ValidAssetStringTypes; buildingId?: string; floorId?: string }) => {
    if (!filter) return eb.asset.getAllAsList();
    if (filter.floorId) {
      const floorIndex = mapFloorIdtoSDKArrayIndex(eb, filter.floorId); // returns -1 if it cant find floor
      if (floorIndex === -1) {
        return []; // for non existing floors return empty array
      }
      if (filter.type) {
        // Parking is not a main type but a subtype, as exception here
        if (filter.type === 'room') {
          return (
            eb.asset
              .getAllAsPreSortedMap()
              .by_floor[floorIndex]?.by_type['room']?.filter((a) => a.subtype !== 'parking') || []
          );
        }
        if (filter.type === 'parking') {
          return (
            eb.asset
              .getAllAsPreSortedMap()
              .by_floor[floorIndex]?.by_type['room']?.filter((a) => a.subtype === 'parking') || []
          );
        }
        return eb.asset.getAllAsPreSortedMap().by_floor[floorIndex].by_type[filter.type] || []; // return empty array if floor does not have assets of the type
      }
      return eb.asset.getAllAsPreSortedMap().by_floor[mapFloorIdtoSDKArrayIndex(eb, filter.floorId)].all;
    }

    if (filter.buildingId) {
      const buildingFloors = eb.asset
        .getAllAsPreSortedMap()
        .by_type.building.find((building) => building.id === filter.buildingId)?.floors;
      if (buildingFloors) {
        return buildingFloors.reduce((acc, cur) => {
          if (filter.type) {
            const floorAssets = eb.asset.getAllAsPreSortedMap().by_floor[mapFloorIdtoSDKArrayIndex(eb, cur)];
            if (!floorAssets) return acc;
            if (filter.type === 'parking') {
              return [...acc, ...(floorAssets.by_type['room']?.filter((a) => a.subtype === 'parking') || [])];
            }
            if (filter.type === 'room') {
              return [...acc, ...(floorAssets.by_type['room']?.filter((a) => a.subtype !== 'parking') || [])];
            }
            return [...acc, ...(floorAssets.by_type[filter.type] || [])];
          }
          return [...acc, ...eb.asset.getAllAsPreSortedMap().by_floor[mapFloorIdtoSDKArrayIndex(eb, cur)].all];
        }, [] as Array<AssetObjectTypes>);
      }
      return [];
      // Figure out the floors of the building, and combine the pre sorted assets of each floor
    }

    if (filter.type) {
      if (filter.type === 'parking') {
        return eb.asset.getAllAsPreSortedMap().by_type['room']?.filter((a) => a.subtype === 'parking') || [];
      }
      if (filter.type === 'room') {
        return eb.asset.getAllAsPreSortedMap().by_type['room']?.filter((a) => a.subtype !== 'parking') || [];
      }
      return eb.asset.getAllAsPreSortedMap().by_type[filter.type] || [];
    }

    return eb.asset.getAllAsList();
  };
  return {
    get: <T>(eb: Eb, filter?: { type?: ValidAssetStringTypes; buildingId?: string; floorId?: string }): T[] =>
      filterAssets(eb, filter),
    onUpdate: (eb: Eb, callback: post_location_load_event_callback) => [
      eb.addHook(CORE_EVENTS.POST_LOCATION_LOAD, callback),
      eb.addHook(CORE_EVENTS.POST_ASSET_SORT, callback),
    ],
  };
};

const availableLayers = () => {
  return {
    get: (eb: Eb) => {
      const availableLayersMap = eb.map.view.getAvailableLayersAsMap();
      const availableLayerObjects = eb.map.layer
        .getAllAsList()
        .filter((layer: Layer) => availableLayersMap[layer.name]);
      return availableLayerObjects;
    },
    onUpdate: (eb: Eb, callback: post_floor_change_event_callback) => [
      eb.map.addHook(MAP_EVENTS.POST_FLOOR_CHANGE, callback),
    ],
  };
};

const activeLayers = () => {
  return {
    get: (eb: Eb) => eb.map.layer.getAllAsList().filter((layer: Layer) => eb.map.view.isLayerActive(layer)),
    // TODO: Specify callback
    onUpdate: (eb: Eb, callback: hookCallback) => [eb.map.addHook(MAP_EVENTS.POST_LAYER_UPDATE, callback)],
  };
};

/**
 * function: stateHelper
 *
 * Add each state helper here for export. Only add read methods, do not add
 * helper functions that changes data, e.g. eb.location.loadById(nextLocationId)
 */

export const stateHelper = {
  location: {
    current: currentLocation(),
    all: allLocationsInCurrentOrganisation(),
    byId: locationById(),
  },
  building: {
    current: currentBuilding(),
    allInCurrentLocation: allBuildingsInCurrentLocation(),
    allInOrganisation: allBuildingsInOrganisation(),
  },
  floor: {
    current: currentFloor(),
    allByBuildingId: getAllFloorsByBuildingId(),
    allInCurrentLocation: getAllFloorsInCurrentLocation(),
    allInCurrentBuilding: getAllFloorsInCurrentBuilding(),
    byFloorId: getFloorByFloorId(),
  },
  notification: {
    all: allNotifications(),
    currentLocation: currentLocationNotifications(),
  },
  asset: {
    byIdInCurrentLocation: assetByIdInCurrentLocation(),
    byIdInOrganisations: () => null,
    all: assetGetAll(),
  },
  user: {
    current: user(),
  },
  layer: {
    availableLayers: availableLayers(),
    activeLayers: activeLayers(),
  },
};

// TODO: remove this temporary state helper before release
window['stateHelper'] = stateHelper;
