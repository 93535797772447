export type Color =
  | 'primary'
  | 'primary-light'
  | 'primary-dark'
  | 'secondary'
  | 'secondary-light'
  | 'secondary-dark'
  | 'tertiary'
  | 'tertiary-light'
  | 'tertiary-dark'
  | 'background'
  | 'background-dark'
  | 'info'
  | 'info-light'
  | 'info-dark'
  | 'success'
  | 'success-dark'
  | 'error'
  | 'error-dark'
  | 'warning'
  | 'warning-dark'
  | 'disabled'
  | 'disabled-dark'
  | 'transparent'
  | 'input'
  | 'input-dark'
  | 'input-light'
  | 'input-icon'
  | 'label'
  | 'dark-transparent'
  | 'offline'
  | 'offline-dark';
export type AssetColor =
  | 'primary'
  | 'primary-light'
  | 'primary-dark'
  | 'secondary'
  | 'secondary-light'
  | 'secondary-dark'
  | 'tertiary'
  | 'tertiary-light'
  | 'tertiary-dark'
  | 'success'
  | 'sucess-dark'
  | 'error'
  | 'error-dark'
  | 'warning'
  | 'warning-dark'
  | 'info'
  | 'info-dark'
  | 'info-light'
  | 'transparent'
  | 'background'
  | 'label'
  | 'dark-transparent'
  | 'offline'
  | 'offline-dark';
export type Shadow = 'secondary' | 'shadow';
export type Width =
  | 'auto'
  | 'xxxxl'
  | 'xxxl'
  | 'xxl'
  | 'xl'
  | 'lg'
  | 'md'
  | 'sm'
  | 'xs'
  | 'xxs'
  | 'xxxs'
  | 'xxxxs'
  | 'root';
export type Height =
  | 'auto'
  | 'xxxxl'
  | 'xxxl'
  | 'xxl'
  | 'xl'
  | 'lg'
  | 'md'
  | 'sm'
  | 'xs'
  | 'xxs'
  | 'xxxs'
  | 'xxxxs'
  | 'root';
export type Size =
  | 'auto'
  | 'xxxxl'
  | 'xxxl'
  | 'xxl'
  | 'xl'
  | 'lg'
  | 'md'
  | 'sm'
  | 'xs'
  | 'xxs'
  | 'xxxs'
  | 'xxxxs'
  | 'root';
// TODO: Replace all uses of font-* with the new font color vars. The types are still here so that the build doesn't fail.
export type FontColor =
  | 'primary-font'
  | 'secondary-font'
  | 'tertiary-font'
  | 'input-font'
  | 'input-placeholder'
  | 'background-font'
  | 'label-font';
export type FontWeight = 'bold' | 'bolder' | 'normal' | 'lighter';

export const mainColors = [
  'primary',
  'secondary',
  'tertiary',
  'background',
  'info',
  'success',
  'warning',
  'error',
  'disabled',
  'input',
  'label',
] as const;

export type MainColorsType = (typeof mainColors)[number];

export const allColors = [
  'primary',
  'primary-light',
  'primary-dark',
  'secondary',
  'secondary-light',
  'secondary-dark',
  'tertiary',
  'tertiary-light',
  'tertiary-dark',
  'background',
  'background-light',
  'background-dark',
  'info',
  'info-light',
  'info-dark',
  'success',
  'success-dark',
  'error',
  'error-dark',
  'warning',
  'warning-dark',
  'disabled',
  'disabled-dark',
  'input',
  'input-light',
  'input-dark',
  'label',
  'offline',
  'offline-dark',
];

export type allColorsType = (typeof allColors)[number];
