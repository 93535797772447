export const CORE_EVENTS = {
  ON_ASSET_ACTIVITY_ALERT: 'on_asset_activity_alert',
  POST_ASSET_ACTIVITY_ALERT_CHANGE: 'post_asset_activity_alert_change',
  POST_ASSET_DP_UPDATE: 'post_asset_dp_update',
  POST_ASSET_FLOOR_CHANGE: 'post_asset_floor_change',
  POST_ASSET_SORT: 'post_asset_sort',
  POST_ASSET_UPDATE: 'post_asset_update',
  POST_CONNECTION_STATE_CHANGE: 'post_connection_state_change',
  /**
   * POST_ERROR
   * Event emitted when some of the SDK functions fail.
   * Generally, one time functions will throw error error when executing it and these can be try catched
   * Respectively, automated/timed functions such as eb.connect() won't throw an error. Instead these can be accessed through this hook
   * Note: Some functions might not give an error at all yet. If previous methods don't work, SDK might need updates.
   */
  POST_ERROR: 'post_error',
  POST_EXTERNAL_OBJECT_UPDATE: 'post_external_object_update',
  /**
   * POST_LOCATION_ASSET_UPDATE
   * Event emitted when asset in a location updates
   */
  POST_LOCATION_ASSET_UPDATE: 'post_asset_location_update',
  /**
   * POST_LOCATION_UPDATE
   * Event emitted when a location object in current organisation is updated,
   * common use case is someone modified the location from admin panel
   */
  POST_LOCATION_UPDATE: 'post_location_update',
  /**
   * POST_LOCATION_LOAD
   * Event emitted when location initially loads and if location is changes.
   */
  POST_LOCATION_LOAD: 'post_location_load',
  POST_MEASUREMENT_UPDATE: 'post_measurement_update',
  /**
   * POST_NOTIFICATION_UPDATE
   * Event emitted when notification is created, updated or deleted
   */
  POST_NOTIFICATION_UPDATE: 'post_notification_update',
  POST_ORGANIZATION_UPDATE: 'post_organization_update',
  POST_ORGANIZATION_LOAD: 'post_organization_load',
  POST_SENSOR_UPDATE: 'post_sensor_update',
  POST_USER_UPDATE: 'post_user_update',
  PRE_ASSET_DP_UPDATE: 'pre_asset_dp_update',
  PRE_ASSET_PARSE: 'pre_asset_parse',
};

export const MAP_EVENTS = {
  ON_ASSET_FOLLOW_CHANGE: 'on_asset_follow_change',
  POST_ASSET_ACTIVITY_CHANGE: 'post_asset_activity_change',
  POST_ASSET_HIGHLIGHT_CHANGE: 'post_asset_highlight_change',
  POST_EDITOR_CHANGE: 'post_editor_change',
  POST_FLOOR_CHANGE: 'post_floor_change',
  POST_LAYER_UPDATE: 'post_layer_update',
  POST_MAP_FRAME: 'post_map_frame',
  POST_MODE_CHANGE: 'post_mode_change',
  POST_REPORT_CHANGE: 'post_report_change',
  POST_UNDO_BUFFER_CHANGE: 'post_undo_buffer_change',
  POST_VIEW_CHANGE: 'post_view_change',
  POST_WAYFIND_ROUTE_CHANGE: 'post_wayfind_route_change',
  PRE_CLICK_PROCESSING: 'pre_click_processing',
};
