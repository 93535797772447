import { Theme, useTheme } from '@mui/material';
import { debounce, isBrowserFirefox, isBrowserSafari, isTouchEnabledOnDevice } from '@utils/general';
import React, { createContext, useEffect, useMemo, useState } from 'react';

export interface ScreenSizeContext {
  width: number;
  height: number;
  heightWithoutVirtualKeyboard: number;
  theme: Theme;
}

export interface ScreenSizeContextProps {
  children: React.ReactNode;
}

export const ScreenSizeContext = createContext({} as ScreenSizeContext);

interface View {
  vw: number;
  vh: number;
}

const currentScreen = {
  orientation: isBrowserSafari ? '' : screen.orientation.type,
  height: document.documentElement.clientHeight,
};

export const ScreenSizeWrapper: React.FC<ScreenSizeContextProps> = ({ children }: ScreenSizeContextProps) => {
  const [view, setView] = useState<View>({
    vw: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
    vh: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  });
  const [heightWithoutVirtualKeyboard, setHeightWithoutVirtualKeyboard] = useState<number>(window.innerHeight);

  const theme = useTheme();

  const adjustScreenDimensionsForCurrentOrientation = () => {
    currentScreen.orientation = screen.orientation.type;
    currentScreen.height = window.innerHeight;

    // Set height of the map
    const canvas = document.querySelector('canvas[id=map_area]');
    canvas?.setAttribute('style', `width: width=device-width, height: ${currentScreen.height}`);

    if (isBrowserFirefox) {
      // Set height of the body (firefox)
      document.body.style.height = `${currentScreen.height + 1}px`;
    }
  };

  const windowSize = () => {
    const viewWidth = document.documentElement.clientWidth;
    const viewHeight = document.documentElement.clientHeight;

    if (isTouchEnabledOnDevice && !isBrowserSafari) {
      // Run only for touch screens & other browsers than Safari, as on Safari's virtual keyboard doesn't resize the window but only scrolls it
      const isOrientationChanged = currentScreen.orientation !== screen.orientation.type;
      if (isOrientationChanged || window.innerHeight > currentScreen.height) {
        adjustScreenDimensionsForCurrentOrientation();
      }
    }

    setHeightWithoutVirtualKeyboard(window.innerHeight);
    setView(() => {
      return {
        vw: viewWidth,
        vh: viewHeight,
      };
    });
  };

  useEffect(() => {
    windowSize();
    if (isBrowserFirefox && isTouchEnabledOnDevice) {
      // Prevent firefox from adjusting map size when virtual keyboard is open
      adjustScreenDimensionsForCurrentOrientation();
    }

    const eventHandler = debounce(windowSize, 50);
    window.addEventListener('resize', eventHandler);
    return () => {
      window.removeEventListener('resize', eventHandler);
    };
  }, []);

  const contextValue = useMemo(() => {
    return {
      width: view.vw,
      height: view.vh,
      heightWithoutVirtualKeyboard: heightWithoutVirtualKeyboard,
      theme,
    };
  }, [view, theme]);
  return <ScreenSizeContext.Provider value={contextValue}>{children}</ScreenSizeContext.Provider>;
};
