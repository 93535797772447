type BrowserName =
  | 'Mozilla Firefox'
  | 'Samsung Internet'
  | 'Opera'
  | 'Microsoft Internet Explorer'
  | 'Microsoft Edge (Legacy)'
  | 'Microsoft Edge (Chromium)'
  | 'Google Chrome or Chromium'
  | 'Apple Safari'
  | 'unknown';

const sUsrAg: string = navigator.userAgent;

export const getBrowserName = (): BrowserName => {
  if (sUsrAg.indexOf('Firefox') > -1) {
    return 'Mozilla Firefox';
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    return 'Samsung Internet';
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    return 'Opera';
  } else if (sUsrAg.indexOf('Trident') > -1) {
    return 'Microsoft Internet Explorer';
  } else if (sUsrAg.indexOf('Edge') > -1) {
    return 'Microsoft Edge (Legacy)';
  } else if (sUsrAg.indexOf('Edg') > -1) {
    return 'Microsoft Edge (Chromium)';
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    return 'Google Chrome or Chromium';
  } else if (sUsrAg.indexOf('Safari') > -1) {
    return 'Apple Safari';
  } else {
    return 'unknown';
  }
};

export const isBrowser = (browserName: BrowserName) => {
  if (browserName === getBrowserName()) return true;
  return false;
};

export const isIOS = sUsrAg.indexOf('iPhone') > -1;
