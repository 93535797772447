import React from 'react';
import ButtonStyles from './Button.module.css';
import { withStyleWrapper } from '../../hoc/withStyle';
import { classNames } from '../../utils/cn';
import Ripple from '../Ripple/Ripple';
import { ButtonContent } from './ButtonContent';

type IconAlignment = 'right' | 'left';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isSquare?: boolean;
  imgSrc?: string;
  imgAlt?: string;
  iconAlignment?: IconAlignment;
  icon?: React.ReactNode;
  isLoading?: boolean;
  isToggleSwitchLeft?: boolean;
  isToggleSwitchRight?: boolean;
  isToggleSwitcLeftActive?: boolean;
  isToggleSwitchRightActive?: boolean;
  isTextLeft?: boolean;
  onClick?: (e?: any) => void;
}

export const Button = withStyleWrapper(
  ({
    children,
    isSquare = false,
    className = '',
    imgSrc,
    imgAlt,
    iconAlignment = 'right',
    icon,
    isLoading = false,
    isToggleSwitchLeft = false,
    isToggleSwitchRight = false,
    isToggleSwitcLeftActive,
    isToggleSwitchRightActive,
    isTextLeft = false,
    onClick,
    ...props
  }: ButtonProps) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement, PointerEvent>) => {
      onClick && onClick(e);
      if (!e.nativeEvent?.pointerType) return;
      // If element was pressed with tab+enter, don't blur the component
      e.currentTarget?.blur();
    };

    return (
      <button
        className={`${ButtonStyles.button} ${classNames({
          [ButtonStyles.square]: isSquare,
          [ButtonStyles.toggleSwitchLeft]: isToggleSwitchLeft,
          [ButtonStyles.toggleSwitchRight]: isToggleSwitchRight,
        })} ${className}`}
        onClick={handleClick}
        {...props}
      >
        <ButtonContent
          imgSrc={imgSrc}
          imgAlt={imgAlt}
          icon={icon}
          isLoading={isLoading}
          isTextLeft={isTextLeft}
          iconAlignment={iconAlignment}
        >
          {children}
        </ButtonContent>
        {isToggleSwitchLeft && (
          <div
            className={ButtonStyles.circleIconLeft}
            style={{
              borderColor: isToggleSwitcLeftActive ? 'var(--color-primary-icon)' : 'var(--color-secondary-icon)',
            }}
          >
            {isToggleSwitcLeftActive && <div className={ButtonStyles.circleInsideLeft}></div>}
          </div>
        )}
        {isToggleSwitchRight && (
          <div
            className={ButtonStyles.circleIconRight}
            style={{
              borderColor: isToggleSwitchRightActive ? 'var(--color-primary-icon)' : 'var(--color-secondary-icon)',
            }}
          >
            {isToggleSwitchRightActive && <div className={ButtonStyles.circleInsideRight}></div>}
          </div>
        )}
        <Ripple />
      </button>
    );
  },
);
