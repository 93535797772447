export const decorations = {
  // Midsummer 2023 hats
  // midsummer_23_whisk: {
  //   texture_path: './img/decorations/vihta512.png',
  //   description: 'Midsummer whisk',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 1.5,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // midsummer_23_bonfire: {
  //   texture_path: './img/decorations/kokko512.png',
  //   description: 'Midsummer bonfire',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 1.5,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // midsummer_23_wreath: {
  //   texture_path: './img/decorations/seppele512.png',
  //   description: 'Midsummer wreath',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 1.5,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // EB Office realease hats below, consultation with Jonna is needed on how theese should be sized and positioned
  // bread: {
  //   texture_path: './img/decorations/or23_bread.png',
  //   description: 'bread',
  //   texture: null,
  //   rel_x: 0,
  //   rel_y: 0,
  //   rel_width: 2,
  //   rel_height: 2,
  //   rel_rot: 0.0,
  // },
  // cup: {
  //   texture_path: './img/decorations/or23_cup.png',
  //   description: 'cup',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 2,
  //   rel_height: 2,
  //   rel_rot: 0.0,
  // },
  // duck: {
  //   texture_path: './img/decorations/or23_duck.png',
  //   description: 'duck',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 2,
  //   rel_height: 2,
  //   rel_rot: 0.0,
  // },
  // keyboard: {
  //   texture_path: './img/decorations/or23_keyboard.png',
  //   description: 'keyboard',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 2.1,
  //   rel_height: 2.1,
  //   rel_rot: 0.0,
  // },
  // pocket: {
  //   texture_path: './img/decorations/or23_pocket.png',
  //   description: 'pocket',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.05,
  //   rel_width: 2.0,
  //   rel_height: 2.0,
  //   rel_rot: 0.0,
  // },
  // tie: {
  //   texture_path: './img/decorations/or23_tie.png',
  //   description: 'tie',
  //   texture: null,
  //   rel_x: 0.01,
  //   rel_y: 0.0,
  //   rel_width: 2.0,
  //   rel_height: 2.0,
  //   rel_rot: 0.0,
  // },
  // VALENTINES HATS BELOW
  // cupid_1: {
  //   texture_path: './img/decorations/EBhats_valentine_21_cupid01.png',
  //   description: 'Cupid',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 1.5,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // cupid_2: {
  //   texture_path: './img/decorations/EBhats_valentine_21_cupid02.png',
  //   description: 'Amor',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 1.5,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // heart: {
  //   texture_path: './img/decorations/EBhats_valentine_21_heart.png',
  //   description: 'Heart',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 1.5,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // flowers: {
  //   texture_path: './img/decorations/EBhats_valentine_21_redANDblue.png',
  //   description: 'Flower crown',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.0,
  //   rel_width: 1.5,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // // CORONA HATS BELOW
  // facemask_blue: {
  //   texture_path: './img/decorations/facemask_blue_256.png',
  //   description: 'The original',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.19,
  //   rel_width: 1,
  //   rel_height: 1,
  //   rel_rot: 0.0,
  // },
  // facemask_green: {
  //   texture_path: './img/decorations/facemask_green_256.png',
  //   description: 'Mint green',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.19,
  //   rel_width: 1,
  //   rel_height: 1,
  //   rel_rot: 0.0,
  // },
  // facemask_blue_fully_vaccinated_with_syringes: {
  //   texture_path: './img/decorations/EBhats_corona21_vaccinated_blue.png',
  //   description: 'Fully Vaccinated',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.11,
  //   rel_width: 1.4,
  //   rel_height: 1.4,
  //   rel_rot: 0.0,
  // },
  // facemask_blue_with_syringes: {
  //   texture_path: './img/decorations/EBhats_corona21_vaccinated_blue3.png',
  //   description: 'Mask & Syringes',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.11,
  //   rel_width: 1.4,
  //   rel_height: 1.4,
  //   rel_rot: 0.0,
  // },
  // facemask_ping: {
  //   texture_path: './img/decorations/facemask_pink_256.png',
  //   description: 'Fuchsia pink',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.19,
  //   rel_width: 1,
  //   rel_height: 1,
  //   rel_rot: 0.0,
  // },
  // // WINTER HATS 2019 BELOW
  // green_hat: {
  //   texture_path: './img/decorations/green_hat.png',
  //   description: 'Green hat',
  //   texture: null,
  //   rel_x: 0.2,
  //   rel_y: 0.4,
  //   rel_width: 0.9,
  //   rel_height: 0.9,
  //   rel_rot: 0.0,
  // },
  // red_hat: {
  //   texture_path: './img/decorations/red_hat.png',
  //   description: 'Red hat',
  //   texture: null,
  //   rel_x: -0.2,
  //   rel_y: 0.4,
  //   rel_width: 0.9,
  //   rel_height: 0.9,
  //   rel_rot: 0.0,
  // },
  // scard_cap: {
  //   texture_path: './img/decorations/scarf_cap.png',
  //   description: 'Scarf & cap',
  //   texture: null,
  //   rel_x: 0.011,
  //   rel_y: 0.0,
  //   rel_width: 1.8,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // wreath: {
  //   texture_path: './img/decorations/wreath.png',
  //   description: 'Winter wreath',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.23,
  //   rel_width: 1.5,
  //   rel_height: 1.5,
  //   rel_rot: 0.0,
  // },
  // // WINTER 2020 HATS BELOW
  // winter_20_snowman: {
  //   texture_path: './img/decorations/winter_20_snowman.png',
  //   description: 'Snowman',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.03,
  //   rel_width: 1.7,
  //   rel_height: 1.7,
  //   rel_rot: 0.0,
  // },
  // winter_20_wreath: {
  //   texture_path: './img/decorations/winter_20_wreath.png',
  //   description: 'Winter wreath',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.03,
  //   rel_width: 1.7,
  //   rel_height: 1.7,
  //   rel_rot: 0.0,
  // },
  // winter_20_crown: {
  //   texture_path: './img/decorations/winter_20_crown.png',
  //   description: 'Ice crown',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.03,
  //   rel_width: 1.85,
  //   rel_height: 1.8,
  //   rel_rot: 0.0,
  // },
  // winter_20_elf: {
  //   texture_path: './img/decorations/winter_20_elf.png',
  //   description: 'Red hat',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.03,
  //   rel_width: 1.85,
  //   rel_height: 1.8,
  //   rel_rot: 0.0,
  // },
  // winter_20_pompom: {
  //   texture_path: './img/decorations/winter_20_pompom.png',
  //   description: 'Scarf & cap',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.03,
  //   rel_width: 1.9,
  //   rel_height: 1.9,
  //   rel_rot: 0.0,
  // },
  // // HALLOWEEN HATS BELOW
  // ghost: {
  //   texture_path: './img/decorations/Ghost.png',
  //   description: 'Oscar The Ghost',
  //   texture: null,
  //   rel_x: 0.036,
  //   rel_y: -0.25,
  //   rel_width: 1.6,
  //   rel_height: 1.6,
  //   rel_rot: 0.0,
  // },
  // pumpking: {
  //   texture_path: './img/decorations/PumpkingKing.png',
  //   description: 'The PumpKing',
  //   texture: null,
  //   rel_x: 0.01,
  //   rel_y: -0.1,
  //   rel_width: 1.9,
  //   rel_height: 1.9,
  //   rel_rot: 0.0,
  // },
  // skeleton: {
  //   texture_path: './img/decorations/SpookyScarySkeleton.png',
  //   description: 'The Spooky Scary Skeleton',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.201,
  //   rel_width: 2.2,
  //   rel_height: 2.2,
  //   rel_rot: 0.0,
  // },
  // EASTER HATS BELOW
  // pb_bunny_ears: {
  //   texture_path: './img/decorations/pb_bunny.png',
  //   description: 'The classic',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.21,
  //   rel_width: 2.5,
  //   rel_height: 2.5,
  //   rel_rot: 0.0,
  // },
  // easter_egg: {
  //   texture_path: './img/decorations/easter_egg.png',
  //   description: 'Eggcellent choice',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.05,
  //   rel_width: 1.4,
  //   rel_height: 1.4,
  //   rel_rot: 0.0,
  // },
  // spring_chick: {
  //   texture_path: './img/decorations/spring_chick.png',
  //   description: 'Crazy bird',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: 0.1,
  //   rel_width: 1.4,
  //   rel_height: 1.4,
  //   rel_rot: 0.0,
  // },
  // easter_faberg: {
  //   texture_path: './img/decorations/EBhats_easter_faber.png',
  //   description: 'Fabergé egg',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.1,
  //   rel_width: 2.3,
  //   rel_height: 2.3,
  //   rel_rot: 0.0,
  // },
  // easter_cock: {
  //   texture_path: './img/decorations/EBhats_easter_cock.png',
  //   description: 'Cocky rooster',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.05,
  //   rel_width: 2.1,
  //   rel_height: 2.1,
  //   rel_rot: 0.0,
  // },
  // catkin_2: {
  //   texture_path: './img/decorations/catkin_2.png',
  //   description: 'Easter willow',
  //   texture: null,
  //   rel_x: -0.01,
  //   rel_y: 0.05,
  //   rel_width: 1.3,
  //   rel_height: 1.3,
  //   rel_rot: 0.0,
  // },
  // witch: {
  //   texture_path: './img/decorations/witch.png',
  //   description: 'Flying witch',
  //   texture: null,
  //   rel_x: -0.15,
  //   rel_y: -0.2,
  //   rel_width: 1.6,
  //   rel_height: 1.6,
  //   rel_rot: 0.0,
  // },
  // easter_flowers: {
  //   texture_path: './img/decorations/EBhats_easter_flowers.png',
  //   description: 'Flower crown',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.15,
  //   rel_width: 2.3,
  //   rel_height: 2.3,
  //   rel_rot: 0.0,
  // },
  // catch: {
  //   texture_path: './img/decorations/catch.png',
  //   description: 'Purrfect partner in crime',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.09,
  //   rel_width: 1.8,
  //   rel_height: 1.8,
  //   rel_rot: 0.0,
  // },
  // easter_grass: {
  //   texture_path: './img/decorations/EBhats_easter_grass.png',
  //   description: 'Grass plant',
  //   texture: null,
  //   rel_x: 0.0,
  //   rel_y: -0.1,
  //   rel_width: 2.1,
  //   rel_height: 2.1,
  //   rel_rot: 0.0,
  // },
};
