import { decorations } from '@config/decorations';
import { SEARCH_ASSET_TYPES } from './search';

// App config is the default config loaded by the app. Each first level key of the config
// object can be overided on any other level such as organisation, location or floor.

export const defaultConfiguration = {
  // default available layers are provided by SDK eb.getBaseConfig().availableLayers
  // only add extra ones here
  availableLayers: [
    'desk_reserver',
    'container_fill_rate',
    'medical_equipment',
    'bed',
    'wheelchair',
    'cleaning',
    'counter',
  ],
  defaultLayers: [
    'floorplan',
    'info',
    'desk_usage',
    'room_reservation',
    'co-workers',
    'desk_reserver',
    'container_fill_rate',
    'counter',
  ],
  removeLayers: [],
  addLayers: [],
  hourStart: 7,
  hourEnd: 18,
  cleanDeskPolicy: 120,
  cleanDeskPhases: [
    { value: 0.0, color: [240 / 255, 75 / 255, 89 / 255, 1.0] },
    { value: 0.08, color: [240 / 255, 75 / 255, 89 / 255, 1.0] },
    { value: 0.081, color: [248 / 255, 172 / 255, 61 / 255, 1.0] },
    { value: 0.999, color: [248 / 255, 172 / 255, 61 / 255, 1.0] },
    { value: 1.0, color: [45 / 255, 156 / 255, 215 / 255, 1.0] },
  ],
  roomUsageStatusColors: [
    [45 / 255, 156 / 255, 215 / 255, 0.85],
    [248 / 255, 172 / 255, 61 / 255, 0.85],
    [240 / 255, 75 / 255, 89 / 255, 0.85],
  ],
  roomUsageMissingColor: [0.35, 0.35, 0.35, 0.7],
  roomUsageNotApplicableColor: [0.75, 0.75, 0.75, 0.25],
  // --color-tt: rgb(70, 201, 241);
  mapBackgroundColor: [70 / 255, 201 / 255, 241 / 255, 1.0],
  // How long will manual check-in / manual location last on the map. default: 12hrs
  manualLocationTimeout: 12 * 60 * 60 * 1000,
  // Do service tickets and voices require moderation
  ticketsVoicesRequireModeration: false,
  // Do end-users need to select a category when leaving a service ticket
  ticketsRequireCategory: false,
  // Do end-users have the option to add their email to the ticket
  ticketsAskForEmail: false,
  // Do end-users have the option to upload image to the ticket
  ticketAttachmentEnabled: false,
  zoneProperties: {
    generic: {
      name: 'Unknown zone',
      color: [200 / 255, 0 / 255, 0 / 255, 0.85],
    },
    communication: {
      name: 'Communication',
      color: [255 / 255, 255 / 255, 255 / 255, 0.85],
    },
    collaboration: {
      name: 'Collaboration',
      color: [127 / 255, 219 / 255, 247 / 255, 0.85],
    },
    concentration: {
      name: 'Concentration',
      color: [139 / 255, 229 / 255, 164 / 255, 0.85],
    },
    chilling: {
      name: 'Chilling out',
      color: [191 / 255, 191 / 255, 191 / 255, 0.85],
    },
  },
  labels: {
    voice: ['space', 'tech', 'culture', 'work', 'other'],
    ticket: [
      { name: 'it', emailRecipients: [] },
      { name: 'cleaning', emailRecipients: [] },
      { name: 'maintenance', emailRecipients: [] },
      { name: 'facility', emailRecipients: [] },
    ],
  },
  // By default everyone is able to view reports! Note: these are strictly used for hiding
  // the report views, we don't have a similar check server side meaning users _can_ still
  // query the API for report data
  reportViewingRolesAtLeast: {
    location: 'regular',
    desk: 'regular',
    room: 'regular',
    environment: 'regular',
  },
  // Below you can set up a custom consent text that appears on the profile page below
  // the location tag id field. Originally made for If.
  customLocationTagIdConsent: {
    en: null,
    fi: null,
  },
  // Privacy policy link for customLocationTagIdConsent
  privacyPolicyLink: null,
  wayfindingEnabled: false,
  // Easy booking. EB1, do not use in code
  assetBookingFeature: false,
  // New booking configuration
  booking: {
    showUserBookings: false,
    forceShowUserBookings: false,
  },
  /*
    Minutes, determines the booking duration multiplier for 2 click booking
    and default booking slot duration for asset card booking
  */
  bookingSlotDurationForDesk: 120,
  bookingSlotDurationForRoom: 30,
  bookingSlotDurationForParking: 240,
  bookingSlotDurationForOther: 120,
  // Display a all day booking button in booking panel
  allDayBookingButton: false,
  // Toggle on whether to use new Taitori URL format when opening embedded Taitori
  // UI for full Taitori customers
  useTaitoriEmbedURLv2: false,
  decorations: decorations,
  // Show "Chat in Teams" button
  isTeamsChatEnabled: false,
  // Favorite assets feature
  isFavoritesEnabled: false,
  // automatically toggle campus view
  campusAutoZoomActivation: true,
  // distance when campus view is automatically toggled on
  campusAutoZoomThreshold: 100,
  // asset types that can be booked
  bookableAssetTypes: ['desk', 'room'],
  // Reserve assets from now by clicking predefined timeslots
  isTwoClickBookingEnabled: false,
  // Reserve assets for the future from either the asset card or the main menu
  isFutureBookingEnabled: false,
  // Enable speech bubbles for user assets
  isUserSpeechBubblesEnabled: false,
  // If true, hide the subject field from reservations
  isReservationSubjectHidden: false,
  // Days to limit how far in advance a user can book assets
  advancedBookingWindow: {
    desk: 180,
    room: 180,
    parking: 180,
    info: 180,
    default: 180,
  },
  // If true, it will hide the location tag setup and display my location toggle in profile edit
  isLocationTagOptionsHidden: false,
  // If true, sensors can send notifications if sensor value goes outside allowed range
  isSensorNotificationsEnabled: false,
  isOfficeDaysEnabled: false,
  // If true, user can not access profile page to edit profile data. Disables /menu/my-page and its functionality
  isUserProfileDisabled: false,
  searchAssetTypes: SEARCH_ASSET_TYPES,
  // Enables creating and editing assets
  isAssetCreationEnabled: false,
  // valueScales: {
  //   temperature: {
  //     unit: '°C',
  //     steps: [
  //       {
  //         value: 0,
  //         color: [120 / 255, 36 / 255, 242 / 255, 0.65],
  //         text: 'cold', // Text is used with translations, e.g. "layerLimit.temperature.cold"
  //         emoji: 'bad',
  //       },
  //       {
  //         value: 16,
  //         color: [183 / 255, 38 / 255, 252 / 255, 0.65],
  //         text: 'cool',
  //         emoji: 'ok',
  //       },
  //       {
  //         value: 20,
  //         color: [220 / 255, 45 / 255, 230 / 255, 0.65],
  //         text: 'comfortable',
  //         emoji: 'good', // EbSmile
  //       },
  //       {
  //         value: 25,
  //         color: [252 / 255, 38 / 255, 168 / 165, 0.65],
  //         text: 'warm',
  //         emoji: 'ok', // EbNeutral
  //       },
  //       {
  //         value: 28,
  //         color: [242 / 255, 36 / 255, 55, 0.65],
  //         text: 'hot',
  //         emoji: 'bad', // EbAngry
  //       },
  //     ],
  //   },
  //   co2: {
  //     unit: 'ppm',
  //     steps: [
  //       {
  //         value: 0,
  //         color: [133 / 255, 255 / 255, 0 / 255, 0.65], // more Healthy Green
  //         text: 'great',
  //         emoji: 'good',
  //       },
  //       {
  //         value: 400,
  //         color: [102 / 255, 204 / 255, 0 / 255, 0.65], //Healthy Green

  //         text: 'good',
  //         emoji: 'good',
  //       },
  //       {
  //         value: 600,
  //         color: [204 / 255, 153 / 255, 0 / 255, 0.65], //Subdued Yellow

  //         text: 'fair',
  //         emoji: 'ok',
  //       },
  //       {
  //         value: 800,

  //         color: [204 / 255, 0 / 255, 0 / 165, 0.65], //Unhealthy Red
  //         text: 'bad',
  //         emoji: 'bad',
  //       },
  //       {
  //         value: 1000,
  //         color: [102 / 255, 0 / 255, 204 / 255, 0.65], //Hazardous Purple
  //         text: 'veryBad',
  //         emoji: 'bad',
  //       },
  //     ],
  //   },
  // },
};
