import { useMemo } from 'react';
import { withStyleWrapper } from '../../hoc/withStyle';
import { classNames } from '../../utils/cn';
import Ripple from '../Ripple/Ripple';
import { Spinner } from '../Spinner/Spinner';
import { Typography, Variant } from '../Typography/Typography';
import SquareButtonStyles from './SquareButton.module.css';

export interface SquareButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  imgSrc?: string;
  imgAlt?: string;
  iconStyle?: React.CSSProperties;
  isLoading?: boolean;
  iconComponent?: JSX.Element;
  isSquare?: boolean;
  textVariant?: Variant;
  classNameText?: string;
  onClick?: (e?: any) => void;
}

export const SquareButton = withStyleWrapper(
  ({
    imgSrc,
    children,
    className = '',
    imgAlt,
    iconStyle,
    isLoading,
    iconComponent,
    isSquare = false,
    textVariant = 'description-text',
    classNameText,
    onClick,
    ...props
  }: SquareButtonProps) => {
    const imgContent = useMemo(() => {
      if (iconComponent) {
        return iconComponent;
      }
      if (imgSrc) {
        return <img src={imgSrc} className={SquareButtonStyles.icon} alt={imgAlt} style={iconStyle} />;
      }
    }, [iconComponent, imgSrc, iconStyle]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, PointerEvent>) => {
      onClick && onClick(e);
      if (!e.nativeEvent?.pointerType) return;
      // If element was pressed with tab+enter, don't blur the component
      e.currentTarget?.blur();
    };

    return (
      <button
        className={`${SquareButtonStyles.squareButton} ${classNames({
          [SquareButtonStyles.square]: isSquare,
        })} ${className}`}
        onClick={handleClick}
        {...props}
      >
        <Ripple />
        <span className={SquareButtonStyles.iconContainer}>
          {isLoading && <Spinner />}
          {!isLoading && (
            <>
              {imgContent}
              <Typography variant={textVariant} type="span" className={classNameText}>
                {children}
              </Typography>
            </>
          )}
        </span>
      </button>
    );
  },
);
