import { allColors, Color } from '../types';
import { RgbString } from './color';

export const isTypeRgbString = (color?: string): color is RgbString => {
  if (!color) return false;
  if (!color.startsWith('rgb')) return false;

  return true;
};

export const isTypeColor = (color?: string): color is Color => {
  if (!color) return false;
  if (!allColors.includes(color)) return false;

  return true;
};
