import { useLayoutEffect, useState } from 'react';
import rippleStyles from './Ripple.module.css';

const useDebouncedRippleCleanUp = (rippleCount: number, cleanUpFunction: () => void) => {
  useLayoutEffect(() => {
    let bounce: ReturnType<typeof setTimeout>;
    if (rippleCount > 0) {
      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, 2000);
    }

    return () => clearTimeout(bounce);
  }, [rippleCount, cleanUpFunction]);
};

interface Ripple {
  x: number;
  y: number;
  size: number;
}

interface RippleProps {
  style?: React.CSSProperties;
}

const defaultProps = {
  style: {
    animationDuration: '800ms',
  },
};

const Ripple = (defaultProps: RippleProps) => {
  const [rippleArray, setRippleArray] = useState<Ripple[]>([]);
  useDebouncedRippleCleanUp(rippleArray.length, () => {
    setRippleArray([]);
  });

  const addRipple = (event: React.MouseEvent<HTMLElement>) => {
    if (!event.currentTarget) return;
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size = rippleContainer.width > rippleContainer.height ? rippleContainer.width : rippleContainer.height;
    const x = event.pageX - rippleContainer.x - size / 2;
    const y = event.pageY - rippleContainer.y - size / 2;
    const newRipple = {
      x,
      y,
      size,
    };

    setRippleArray([...rippleArray, newRipple]);
  };

  return (
    <div className={rippleStyles.ripple} onMouseDown={addRipple}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) => {
          return (
            <span
              className={rippleStyles.effect}
              key={'span' + index}
              style={{
                top: ripple.y,
                left: ripple.x,
                width: ripple.size,
                height: ripple.size,
                ...defaultProps.style,
              }}
            />
          );
        })}
    </div>
  );
};

Ripple.defaultProps = defaultProps;
export default Ripple;
