import { SessionMode } from '@contexts/session/types';
import React, { Suspense } from 'react';
const KioskRouter = React.lazy(() => import('@router/KioskRouter.tsx'));
const DesktopRouter = React.lazy(() => import('@router/DesktopRouter.tsx'));

function Router({ mode }: { mode: SessionMode }) {
  return mode === 'kiosk' ? (
    <Suspense>
      <KioskRouter />
    </Suspense>
  ) : (
    <Suspense>
      <DesktopRouter />
    </Suspense>
  );
}

export default Router;
