import { RgbString } from '../interfaces/color';
import { isTypeColor } from '../interfaces/isType';

export const mergeHSLA = (hslaWithCorrectColor: string, hslaWithCorrectAlpha: string): string => {
  const values = hslaWithCorrectColor.match(/\d+(\.\d+)?/g);
  const alpha = hslaWithCorrectAlpha.match(/\d+(\.\d+)?/g)?.[3] || '1';

  if (values && values.length === 4) {
    const hue = values[0];
    const saturation = values[1];
    const lightness = values[2];

    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
  }

  return hslaWithCorrectColor;
};

export const hexToObj = (hex: string) => {
  const rgba = hexToRgba(hex);
  const hsla = rgbaToHsla(rgba);

  return { hex, rgba, hsla };
};

export const hexToRgba = (hex: string): RgbString => {
  if (!hex) return 'rgba(0,0,0,1)';
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  const a = parseInt(hex.slice(7, 9), 16) || 1;

  return `rgba(${r},${g},${b},${a})`;
};

export const rgbaToHsla = (rgba: string) => {
  const rgbaArr = parseRgba(rgba);
  if (!rgbaArr) return 'hsla(0,0%,0%,1)';
  const rFraction = rgbaArr[0] / 255;
  const gFraction = rgbaArr[1] / 255;
  const bFraction = rgbaArr[2] / 255;
  const a = rgbaArr[3];

  const max = Math.max(rFraction, gFraction, bFraction);
  const min = Math.min(rFraction, gFraction, bFraction);

  let h = 0;
  let s = 0;
  let l = (max + min) / 2;

  if (max == min) {
    h = s = 0;
  } else {
    const delta = max - min;
    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);

    switch (max) {
      case rFraction:
        h = (gFraction - bFraction) / delta + (gFraction < bFraction ? 6 : 0);
        break;
      case gFraction:
        h = (bFraction - rFraction) / delta + 2;
        break;
      case bFraction:
        h = (rFraction - gFraction) / delta + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return `hsla(${h},${s}%,${l}%,${a})`;
};

export const rgbaToObj = (rgba: string) => {
  const hex = rgbaToHex(rgba);
  const hsla = rgbaToHsla(rgba);

  return { hex, hsla, rgba };
};

export const getOuterBoxColor = (color: string): RgbString => {
  if (color?.startsWith('rgba')) {
    const colorArr = parseRgba(color);
    if (colorArr && colorArr.length === 4) {
      const newColor = dimColor(colorArr);
      return `rgba(${newColor.map(String).join()})`;
    }
  }
  return 'rgba(0,0,0,0.2)';
};

// Get numberical values from the string and assign them into an array
const parseRgba = (colorString: string) => {
  const rgba = colorString.match(/[.?\d]+/g);
  if (rgba?.length === 3) {
    rgba?.push('1');
  }
  return rgba?.map(Number);
};

const rgbaToHex = (rgba: string) => {
  const rgbaArr = parseRgba(rgba);
  if (!rgbaArr) return;
  let r = rgbaArr[0].toString(16);
  let g = rgbaArr[1].toString(16);
  let b = rgbaArr[2].toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return `#${r}${g}${b}`;
};

// Reduce alpha
const dimColor = (colorArray: number[]) => {
  colorArray[3] /= 2;
  return colorArray;
};

export const getDarkerOrLighterColor = (statusColor: string, darkMultiplier = 0.75, lightMultiplier = 0.25) => {
  const assetColor = parseRgba(statusColor);
  const darkerColor = assetColor?.map((color: number, index: number) => {
    return index === 3 ? color : color * darkMultiplier;
  });
  const lighterColor = assetColor?.map((color: number, index: number) => {
    return index === 3 ? color : color + lightMultiplier * (255 - color);
  });
  return {
    mainColor: statusColor,
    darkerColor: `rgba(${darkerColor?.toString()})`,
    lighterColor: `rgba(${lighterColor?.toString()})`,
  };
};

export const getHeaderBackgroundColor = (
  statusColor?: RgbString,
  backgroundColor = 'info',
  isProfile?: boolean,
  isOnline?: boolean,
) => {
  const style: React.CSSProperties = {};
  // AssetCard profile
  if (isProfile && !isOnline) {
    style.backgroundColor = 'var(--color-offline)';
    return style;
  }
  if (statusColor) {
    style.backgroundColor = statusColor;
    return style;
  }
  style.backgroundColor = `var(--color-${backgroundColor})`;
  return style;
};

export const getImageHeaderBackgroundColor = (
  statusColor?: RgbString,
  backgroundColor = 'info',
  isProfile?: boolean,
  isOnline?: boolean,
) => {
  const style: React.CSSProperties = {};
  // AssetCard profile
  if (isProfile && !isOnline) {
    style.backgroundImage = `linear-gradient(var(--color-offline-dark), var(--color-offline-dark)), linear-gradient(to right, var(--color-offline-dark), 50%, var(--color-offline))`;
    style.filter = 'grayscale(100%)';
    return style;
  }
  if (statusColor) {
    let bgColor: string = statusColor;
    const isRgba = bgColor.includes('rgba');
    if (isRgba) {
      // Replace potential alpha value with 1 to avoid transparent background
      const rgbaValues = bgColor.split(',');
      if (rgbaValues[3]) {
        // Alpha value exists
        rgbaValues[3] = '1)';
        bgColor = rgbaValues.toString();
      }
    }
    const darkerColor = getDarkerOrLighterColor(bgColor).darkerColor;
    style.backgroundImage = `linear-gradient(${bgColor}, ${bgColor}), linear-gradient(to left, ${bgColor}, 50%, ${darkerColor})`;
    return style;
  }
  style.backgroundImage = `linear-gradient(var(--color-${backgroundColor}), var(--color-${backgroundColor})), linear-gradient(to left, var(--color-${backgroundColor}), 50%, var(--color-${backgroundColor}-dark))`;
  return style;
};

export const hslaToObj = (hsla: string) => {
  const hex = hslaToHex(hsla);
  const rgba = hexToRgba(hex);

  return { hex, rgba, hsla };
};

export const hslaToHex = (hslaString: string) => {
  const hslaArr = parseHsla(hslaString);
  if (!hslaArr) return '#000000';
  const [h, s, l] = hslaArr;

  const sFraction = s / 100;
  const lFraction = l / 100;

  const k = (n: number) => (n + h / 30) % 12;
  const mul = sFraction * Math.min(lFraction, 1 - lFraction);

  const f = (n: number) => lFraction - mul * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

  const r = Math.round(255 * f(0));
  const g = Math.round(255 * f(8));
  const b = Math.round(255 * f(4));

  return '#' + r.toString(16).padStart(2, '0') + g.toString(16).padStart(2, '0') + b.toString(16).padStart(2, '0');
};

const parseHsla = (hslaString: string) => {
  const hsla = hslaString.match(/\d+/g);
  if (hsla?.length === 3) {
    hsla?.push('1');
  }

  return hsla?.map(Number);
};

// Needed so that transparent gradients for Firefox work properly
export const getTransparentThemeColor = (colorKey: string, alpha = 0) => {
  const colorVal = getComputedStyle(document.documentElement).getPropertyValue(`--color-${colorKey}`);
  const hslaArr = parseHsla(colorVal);
  if (hslaArr?.length !== 4) return 'hsla(0,0%,0%,0)';
  const [h, s, l] = hslaArr;
  return `hsla(${h},${s}%,${l}%,${alpha})`;
};

export const getFontColor = (rgba: RgbString, favorWhite = false) => {
  // This function calulates the brightness of an RGB color and returns either black or white depending on which on has more contrast with it
  const rgbaArr = rgba.match(/[.?\d]+/g);
  // Default to white
  if (!rgbaArr) return hexToObj('#FFFFFF');
  const rgbaNums = rgbaArr.map((val) => Number(val));

  // Convert RGBA to grayscale val, given by the dot-product of [R, G, B] and [0.299, 0.587, 0.114]
  const dotProduct = (vecA: number[], vecB: number[]) =>
    vecA.map((val, idx) => val * vecB[idx]).reduce((acc, curr) => acc + curr);
  const factor = favorWhite ? 0.7 : 1;
  const weights = [0.299 * factor, 0.587 * factor, 0.114 * factor];

  const grayVal = dotProduct(rgbaNums.slice(0, 3), weights);
  const colorFontDark = getComputedStyle(document.documentElement).getPropertyValue('--color-font-dark');
  const betterContrast = grayVal < 128 ? 'hsla(0, 0%, 100%, 1)' : colorFontDark;

  return hslaToObj(betterContrast);
};

/**
 *
 * @param variable expected: var(--color-${backgroundColor})
 * @returns rgba value of the color variable
 */
export const colorVarToRgbString = (variable?: string) => {
  const regExp = variable?.match(/\((.*)\)/);
  if (!regExp) return;

  const colorVar = regExp[1]; // e.g. '--color-info'
  const colorVarName = colorVar.split('color-')[1]; // e.g. 'info'
  if (!isTypeColor(colorVarName)) return;

  const colorValue = getComputedStyle(document.documentElement).getPropertyValue(colorVar); // e.g. 'hsla(202, 84%, 41%, 1)'
  if (!colorValue) return;

  return hslaToObj(colorValue).rgba;
};
