import { SquareButton } from '../SquareButton/SquareButton';
import { WithStyleProps, withStyleWrapper } from '../../hoc/withStyle';
import { IconLegend, IconExclamation, IconAlert, IconMegafone, IconQuestion } from '../Icon';
import AlertStyles from './Alert.module.css';
import { Typography } from '../Typography/Typography';
import { Button, ButtonProps } from '../Button/Button';
import { CSSProperties, useEffect, useState } from 'react';
import { EbClose } from '../IconComponent';

export type Variant = 'error' | 'warning' | 'success' | 'info';

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  variant: Variant;
  message: string;
  button?: AlertButton | null;
  handleClose?: () => void;
  ariaLabelClose: string;
  foregroundColor?: CSSProperties['color'];
}

export interface AlertButton {
  label: string;
  onClick: () => void;
  autoClickTime: number | null;
  props: ButtonProps & WithStyleProps;
}

const getVariantProperties = (variant: Variant) => {
  switch (variant) {
    case 'error':
      return {
        imgSrc: IconExclamation,
        imgAlt: 'icon with exclamation sign',
      };
    case 'warning':
      return {
        imgSrc: IconAlert,
        imgAlt: 'icon with alert sign',
      };
    case 'success':
      return {
        imgSrc: IconMegafone,
        imgAlt: 'icon with megafone sign',
      };
    case 'info':
      return {
        imgSrc: IconLegend,
        imgAlt: 'icon with question sign',
      };
    default:
      return {
        imgSrc: IconQuestion,
        imgAlt: 'icon with legend sign',
      };
  }
};

export const Alert = withStyleWrapper(
  ({
    className = '',
    variant,
    message,
    button,
    handleClose,
    foregroundColor,
    ariaLabelClose,
    ...props
  }: AlertProps) => {
    const [time, setTime] = useState(button?.autoClickTime || null);

    useEffect(() => {
      if (!button?.autoClickTime || !(typeof time === 'number')) return;
      const timer = setInterval(() => {
        setTime(time - 1);
      }, 1000);
      if (time === 0) {
        button.onClick();
        clearTimeout(timer);
      }
      return () => clearTimeout(timer);
    }, [time]);

    return (
      <div className={AlertStyles.alertContainer}>
        <div className={`${AlertStyles.alert} ${className}`} {...props}>
          <div className={AlertStyles.iconContainer}>
            <img
              src={getVariantProperties(variant).imgSrc}
              alt={getVariantProperties(variant).imgAlt}
              className={AlertStyles.icon}
            />
          </div>
          <div className={AlertStyles.contentContainer}>
            <div className={AlertStyles.text}>
              <Typography variant="subtitle" type="span" style={{ color: foregroundColor }}>
                {message}
              </Typography>
            </div>
            {button && (
              <Button height="xxs" {...button.props} onClick={button.onClick} className={AlertStyles.button}>
                {`${button.label}`} {!!time && `(${time})`}
              </Button>
            )}
          </div>
        </div>
        <SquareButton
          iconComponent={
            <EbClose color="var(--color-font-dark);" className={AlertStyles.icon} aria-label={ariaLabelClose} />
          }
          size="xxxxs"
          imgAlt="icon with close sign"
          onClick={handleClose}
          className={AlertStyles.closeButton}
          backgroundColor="transparent"
        />
      </div>
    );
  },
);
