import { util } from '@ebDesign';
import { RgbString } from '@ebDesign/interfaces/color';
import { LayerColorLegends } from '@interfaces/eb/legend';
import { Ticket } from '@interfaces/eb/ticket';
import { Voice } from '@interfaces/eb/voice';

export function hexToEBColorArray(hex: string, alpha: number = 1): number[] {
  // Ensure the hex string starts with a '#' and is of length 7 (e.g., #RRGGBB)
  if (!/^#([A-Fa-f0-9]{6})$/.test(hex)) {
    throw new Error('Invalid hex color format');
  }

  // Extract the red, green, and blue components from the hex string
  const red = parseInt(hex.slice(1, 3), 16) / 255;
  const green = parseInt(hex.slice(3, 5), 16) / 255;
  const blue = parseInt(hex.slice(5, 7), 16) / 255;

  // Return an array [red, green, blue, alpha]
  return [red, green, blue, alpha];
}

export const hslaToEbColor = (color: string) => {
  const rgba = util.hslaToObj(color).rgba;
  const rgbaArr = rgba.match(/[.?\d]+/g);
  if (!rgbaArr) return [0.8, 0.8, 0.8, 1];
  const rgbaNums = rgbaArr.map(Number);
  const a = rgbaNums.length === 4 ? rgbaNums.pop() : 1.0;
  const ebColors = rgbaNums.map((value) => {
    const ebColorVal = Number(parseFloat((value / 255).toFixed(2)));
    return ebColorVal || 0.8;
  });
  return [...ebColors, a];
};

export const SDKColorArrayToRGBString = (color: number[]): string => {
  // Extract the red, green, and blue values from the array
  const [r, g, b] = color.map((channel) => Math.round(channel * 255));

  // Create the RGB string
  const rgbString = `rgb(${r}, ${g}, ${b})`;

  return rgbString;
};

export const SDKColorArrayToRGBAString = (color: number[]): RgbString => {
  // Extract the red, green, and blue values from the array
  const [r, g, b, o] = color.map((channel) => Math.round(channel * 255));

  // Create the RGB string
  return `rgba(${r}, ${g}, ${b}, ${o})`;
};

export function rgbStringToSDKColorArray(rgbColor: string) {
  const rgbPattern = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
  const match = rgbColor.match(rgbPattern);

  if (!match) {
    throw new Error('Invalid RGB string format');
  }

  const rgbArray = match.slice(1, 4).map(Number);

  if (rgbArray.some((value) => value < 0 || value > 255)) {
    throw new Error('RGB values must be between 0 and 255');
  }

  const normalizedArray = rgbArray.map((value) => Math.round((value / 255) * 100) / 100);

  // Return the normalized array
  return normalizedArray;
}

export function removeAlphaAndAdjustRGB(rgbaColor: string): string {
  // Use regular expression to match the rgba values
  const rgbaRegex = /rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/;
  // Extract the RGBA values from the input string
  const matches = rgbaColor.match(rgbaRegex);
  if (matches && matches.length === 5) {
    const red = parseInt(matches[1], 10);
    const green = parseInt(matches[2], 10);
    const blue = parseInt(matches[3], 10);
    const alpha = parseFloat(matches[4]);

    // Calculate the adjusted RGB values
    const adjustedRed = Math.round(red * alpha + (1 - alpha) * 255);
    const adjustedGreen = Math.round(green * alpha + (1 - alpha) * 255);
    const adjustedBlue = Math.round(blue * alpha + (1 - alpha) * 255);

    // Create a new RGB color string with the adjusted RGB values
    return `rgb(${adjustedRed}, ${adjustedGreen}, ${adjustedBlue})`;
  }
  // If the input doesn't match the expected format, return the original color
  return rgbaColor;
}

// Calculate the contrast ratio between the background and text colors
const getContrastRatio = (color1: string, color2: string): number => {
  const lum1 = getRelativeLuminance(color1);
  const lum2 = getRelativeLuminance(color2);
  return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
};

// Calculate the relative luminance of a color
const getRelativeLuminance = (color: string): number => {
  const rgba = color.match(/(\d+(\.\d+)?)/g);
  if (rgba && rgba.length >= 3) {
    const r = parseFloat(rgba[0]) / 255;
    const g = parseFloat(rgba[1]) / 255;
    const b = parseFloat(rgba[2]) / 255;
    const gammaCorrect = (c: number) => (c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4));
    //sRGB
    return 0.2126 * gammaCorrect(r) + 0.7152 * gammaCorrect(g) + 0.0722 * gammaCorrect(b);
  }

  return 0;
};

export function ensureTextContrast(backgroundColor: string, textColor: string, minContrast = 5): string {
  // Check if the contrast ratio is below the threshold
  const backgroundRGBA = util.hslaToObj(backgroundColor)?.rgba;
  const textRGBA = util.hslaToObj(textColor)?.rgba;

  if (backgroundRGBA && textRGBA && getContrastRatio(backgroundRGBA, textRGBA) > minContrast) {
    // If below threshold, use the provided text color
    return textColor;
  } else if (backgroundRGBA && getContrastRatio(backgroundRGBA, 'rgba(255,255,255,1)') > minContrast) {
    return 'rgba(255,255,255,1)';
  } else {
    return 'rgba(0,0,0,1)';
  }
}

const ticketStatus: { [val: number]: string } = { 1: 'Open ticket', 3: 'Work in progress', 2: 'Resolved ticket' };

export const getServiceTicketStatusColor = (activeColorLegends: LayerColorLegends[], ticketItem: Ticket) => {
  return activeColorLegends.reduce((color, item) => {
    if (!color && item.layerName.includes('service')) {
      const statusText = ticketStatus[ticketItem.resolution_status];
      const matchingColor = item.colors.find((legend) => legend.text === statusText);
      if (matchingColor) {
        color = matchingColor.color;
      }
    }

    return color;
  }, '');
};

export const getVoiceStatusColor = (activeColorLegends: LayerColorLegends[], feeling: Voice['feeling']) => {
  return activeColorLegends.reduce((color, item) => {
    if (!color && item.layerName.includes('voice')) {
      const matchingColor = item.colors.find((legend) => legend.text.toLowerCase().includes(feeling));
      if (matchingColor) {
        color = matchingColor.color;
      }
    }

    return color;
  }, '');
};

export const getColorBasedOnBackground = (color: RgbString) => {
  return util.getFontColor(color, true).rgba;
};
