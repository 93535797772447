import { DateTime } from 'luxon';
// VITETODO: check import
import { getEarliestOngoingOrUpcomingBooking, isUserBookingsVisible } from '@helpers/booking';
import { Eb } from '@interfaces/eb/eb';
import { EbMap } from '@interfaces/map/ebMap';
import { EbCore } from '@interfaces/core/ebCore';

export function add_desk_reserve_demo_layer(eb: Eb, eb_map: EbMap, eb_core: EbCore) {
  const dsb = window.dsb;
  if (!dsb) {
    return;
  }
  const layer = eb_map.layer_init();
  layer.name = 'desk_reserver';
  layer.title = 'Desk reservations';
  layer.icon = 'desk';
  layer.draw_funcs[3] = (eb, layer, assets) => {
    const engine = eb._d.map.engine;
    const reserver_dlos = [];
    for (const a of assets) {
      const b = eb._d.assets_map[eb_core.asset_get_floor(eb, a).parent_id];
      const dateLimit = DateTime.now().endOf('day');
      const ongoing_or_next_event = getEarliestOngoingOrUpcomingBooking(a, dateLimit);

      if (ongoing_or_next_event) {
        let user = eb._d.users_map[ongoing_or_next_event.user_id];
        // Only display user avatar if user has given consent to display it
        if (!user || (!isUserBookingsVisible(eb, user) && eb.user.getCurrent().id !== user.id)) {
          // if user is current user we can display avatar
          user = { local_user_asset: { texture_path: '/img/avatar.png' } };
        }

        const dlo = dsb.engine_create_drawable(engine, {
          shader_name: 'texture_transform',
          model_name: 'circle',
          texture_file: user.local_user_asset?.texture_path,
          actively_relative_to: a.visual.base,
          position: [0.0, -0.5, a.height * 0.8],
          scale: [0.8 / a.width, 0.8 / a.height, 1.0],
          click_target: a,
          clickable: true,
          frame_func: (engine, dlo) => {
            const rot = dsb.deg_to_rad(b.north_vector || 0) - eb._d.map.engine.view.rot;
            dlo.uv_mat = dsb.generate_matrix({
              transform: [
                { type: 'translate', v: [0.5, 0.5, 0.0] },
                { type: 'rotate', v: -a.angles[2], axis: [0.0, 0.0, 1.0] },
                { type: 'rotate', v: rot, axis: [0.0, 0.0, 1.0] },
                { type: 'translate', v: [-0.5, -0.5, 0.0] },
              ],
            });
          },
        });
        reserver_dlos.push(dlo);

        reserver_dlos.push(
          dsb.engine_create_drawable(engine, {
            click_target: a,
            clickable: true,
            shader_name: 'flat',
            model_name: 'circle_border',
            actively_relative_to: dlo,
            scale: [1.0, 1.0, 1.0],
            position: [0.0, 0.0, 0.001],
            draw_sub_group: 1,
            shade: a.visual.base.shade,
          }),
        );
      }
    }
    return reserver_dlos;
  };
  layer.asset_types = ['desk'];
  layer.search_types = ['desk'];
  layer.legend_func = eb_map.calculate_desk_usage_legends;
  layer.legend_contains_rt_data = true;
  layer.disables_layers = () => ['report', 'monitoring'];
  eb_map.layer_add(eb, layer);
}
