import { DARK_THEME_OPTIONS, LIGHT_THEME_OPTIONS } from '@config/theme';
import { hexToObj } from '@ebDesign/utils/color';
import storage from '@helpers/storage';
import { CssBaseline, ThemeProvider as MUIThemeProvider, Theme, ThemeOptions, createTheme } from '@mui/material';
import { hslaToEbColor } from '@utils/color';
import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';

declare global {
  interface Window {
    theme: { lightTheme: Theme; darkTheme: Theme }; // Specify the type of your 'theme' here
  }
}

const isDevelopment = import.meta.env.MODE !== 'production';

export type Mode = 'light' | 'dark';

interface ThemeContextType {
  mode: Mode;
  setMode: (mode: Mode) => void;
  lightThemeOptions: ThemeOptions;
  darkThemeOptions: ThemeOptions;
  setLightThemeOptions: (themeOptions: ThemeOptions) => void;
  setDarkThemeOptions: (themeOptions: ThemeOptions) => void;
}

const ThemeContext = createContext<ThemeContextType | null>(null);

interface ThemeProviderProps {
  children: ReactNode;
}

function initialModeState() {
  const storedMode = storage.read('mui-mode');
  switch (storedMode) {
    case 'light':
      return 'light';
    case 'dark':
      return 'dark';
    default: {
      const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      return userPrefersDark ? 'dark' : 'light';
    }
  }
}

function ThemeProvider({ children }: ThemeProviderProps) {
  const [mode, setMode] = useState<Mode>(() => initialModeState());
  const [lightThemeOptions, setLightThemeOptions] = useState(LIGHT_THEME_OPTIONS);
  // const [lightThemeLogo, setLightThemeLogo] = useState(() => eb.location.getCurrentlyLoaded().config.);
  const [darkThemeOptions, setDarkThemeOptions] = useState(DARK_THEME_OPTIONS);

  const lightTheme = useMemo(() => createTheme(lightThemeOptions), [lightThemeOptions]);
  const darkTheme = useMemo(() => createTheme(darkThemeOptions), [darkThemeOptions]);
  const theme = mode === 'light' ? lightTheme : darkTheme;
  if (isDevelopment) {
    console.info(
      `%cDevmode! Current theme is: ${mode}. Type "theme" in console to explore the current theme values. Type toggleTheme() to change`,
      'color: pink; font-weight: bold;',
    );
    window.theme = { lightTheme, darkTheme };
  }

  useEffect(() => {
    const root = document.documentElement;
    // Clear existing theme classes
    root.classList.remove('light-theme', 'dark-theme');
    // Apply the new theme
    root.classList.add(`${mode}-theme`);
    const colorObj = hexToObj(theme.palette.map.main);
    eb._d.map.background_color_override = hslaToEbColor(colorObj.hsla);
    eb_map.map_request_refresh(window.eb);
  }, [mode]);

  return (
    <ThemeContext.Provider
      value={{
        mode,
        setMode,
        lightThemeOptions,
        darkThemeOptions,
        setLightThemeOptions,
        setDarkThemeOptions,
      }}
    >
      <MUIThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
}

export { ThemeContext, ThemeProvider };
