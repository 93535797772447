import i18n from 'i18next';
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';
import LocalBackend from 'i18next-http-backend';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
const PROJECT_ID = 'cf48df3b-bfba-4290-a36a-af0c829bca35'; // retrieved from https://www.locize.app/p/4apgwwrf/settings

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init<ChainedBackendOptions>({
    fallbackLng: 'en',
    debug: false,
    // Define the languages your app supports
    supportedLngs: ['en', 'fi', 'sv', 'nb'],
    // Define the namespace(s)
    ns: ['translation'],
    defaultNS: 'translation',
    // load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [LocalBackend, LocizeBackend],
      backendOptions: [
        {},
        {
          projectId: PROJECT_ID,
          loadPath: 'https://api.locize.app/{{projectId}}/{{version}}/{{lng}}/{{ns}}',
        },
      ],
    },
  });

export default i18n;
