import { isDesktop, isMobile, isSmartTV, isTablet } from 'react-device-detect';

export function truncateText(text = '', maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength - 3) + '...';
}

export const debounce = (fn: (...args: any[]) => any, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

type DebouncedThrottledFunction<T extends (...args: unknown[]) => unknown> = (...args: Parameters<T>) => void;

export function debounceThrottle<T extends (...args: unknown[]) => unknown>(
  func: T,
  delay = 1000,
  maxDelay = 3000,
): DebouncedThrottledFunction<T> {
  let firstInvokeTime: number | null;

  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: unknown, ...args: unknown[]) {
    clearTimeout(timeoutId);
    if (!firstInvokeTime) {
      firstInvokeTime = Date.now();
    }
    const currentTime = Date.now();
    if (currentTime - firstInvokeTime >= maxDelay) {
      firstInvokeTime = null;
      func.apply(this, args);
    } else {
      // postpone
      timeoutId = setTimeout(() => {
        firstInvokeTime = null;
        func.apply(this, args);
      }, delay);
    }
  };
}

export const caseIgnoredCompare = (s1: string, s2: string) => {
  return !s1.localeCompare(s2, undefined, { sensitivity: 'accent' });
};

export const isTouchEnabledOnDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const isBrowserSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isBrowserFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const appFontSizeInPx = parseInt(getComputedStyle(document.documentElement).fontSize);

export const imageCompressionOptions = {
  maxSizeMB: 2,
  useWebWorker: true,
  maxWidthOrHeight: 1000,
};

export const getDevice = () => {
  if (isMobile) return 'Mobile';
  if (isTablet) return 'Tablet';
  if (isDesktop) return 'Desktop';
  if (isSmartTV) return 'SmartTV';
  return 'Unknown';
};
