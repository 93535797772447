import { stateHelper } from '@helpers/state';
import { Eb } from '@interfaces/eb/eb';
import { User } from '@interfaces/eb/user';

export const changeLocation = async (eb: Eb, locationId: string) => {
  if (stateHelper.location.current.get(eb).id === locationId) {
    return;
  }

  return await eb.location.loadById(locationId);
};

export const getCurrentUser = (eb: Eb) => {
  return eb.user.getCurrent();
};

/**
 * Copied from SDK documentation:
 * Gets inherited config value for any configurable object.
 * For example, the clean desk policy of a desk object.
 * Values are inherited in order: own config, parent config (parent object, floor, building), location config, organization config, base/fallback config.
 */
export const getConfig = <T = unknown>(eb: Eb, asset: any, key: string): T => {
  if (
    //TODO: remove this once https://haltian.atlassian.net/browse/EPS-28 is in production
    asset.config &&
    typeof asset.config === 'object' &&
    Object.prototype.hasOwnProperty.call(asset.config, key)
  ) {
    return asset.config[key];
  }
  return eb.getConfigValue(asset, key) as T;
};

export const getRoleAtLeast = (currentUser: User, role: string) => {
  return eb_core.roleAtLeast(currentUser, role);
};
