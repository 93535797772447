import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react';

import { InitialState } from '@index';
import { SessionMode } from './types';

export interface SessionContextType {
  token: string | null; // Token from login or URL
  setToken: Dispatch<SetStateAction<string | null>>;
  auth: string | null; // Auth from URL which is base64 encoded username&password
  isAuthenticated?: boolean; // Token has been determined to be valid using eb.connect
  setIsAuthenticated: Dispatch<SetStateAction<boolean | undefined>>;
  needsRegistration?: boolean; // User needs to create a password, field retrieved from login
  setNeedsRegistration: Dispatch<SetStateAction<boolean | undefined>>;
  temporaryPassword?: string;
  setTemporaryPassword: Dispatch<SetStateAction<string | undefined>>;
  href: string; // href on page initial load
  storedView: string | null; // stored view for infoscreens
  search: string; // search of initial page load
  path: string; // path of initial page load
  sessionMode: SessionMode; // either mobile, desktop or kiosk
  setSessionMode: Dispatch<SetStateAction<SessionMode>>;
  orgId?: string;
  locId?: string;
}

const SessionContext = createContext<SessionContextType | null>(null);

interface SessionProviderProps {
  children: ReactNode;
  initalState: InitialState;
}

function SessionProvider({ children, initalState }: SessionProviderProps) {
  const [token, setToken] = useState<string | null>(initalState.token);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [needsRegistration, setNeedsRegistration] = useState<boolean>();
  const [sessionMode, setSessionMode] = useState<SessionMode>(initalState.mode);
  const [temporaryPassword, setTemporaryPassword] = useState<string>();

  return (
    <SessionContext.Provider
      value={{
        token,
        setToken,
        isAuthenticated,
        setIsAuthenticated,
        needsRegistration,
        setNeedsRegistration,
        temporaryPassword,
        setTemporaryPassword,
        auth: initalState.auth,
        href: initalState.href,
        storedView: initalState.storedView,
        search: initalState.search,
        path: initalState.path,
        sessionMode,
        setSessionMode,
        orgId: initalState.orgId,
        locId: initalState.locId,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export { SessionContext, SessionProvider };
