import { useScreenSize } from '@contexts/screenSize/useScreenSize';
import EbExclamation from '@ebDesign/components/IconComponent/EbExclamation';
import EbTitle from '@ebDesign/components/IconComponent/EbTitle';
import { Typography } from '@ebDesign/components/Typography/Typography';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPageStyles from './ErrorPage.module.css';

export const ErrorPage = ({ message, error }: { message: string; error?: string }) => {
  const [reloadTimer, setReloadTimer] = useState<number>(Math.floor(Math.random() * (120 - 60 + 1)) + 60);

  const { t } = useTranslation();
  const { width, height } = useScreenSize();

  useEffect(() => {
    const interval = setInterval(() => {
      setReloadTimer((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (reloadTimer > 0) return;
    location.reload();
  }, [reloadTimer]);

  const handleLocation = () => {
    window.location.href = window.location.origin;
  };

  return (
    <div style={{ width, height }}>
      <div className={ErrorPageStyles.wrapper}>
        <div className={ErrorPageStyles.contentWrapper}>
          <div className={ErrorPageStyles.iconWrapper}>
            <EbExclamation className={ErrorPageStyles.ebExclamationIcon} color="var(--color-tertiary-icon)" />
            <EbTitle className={ErrorPageStyles.ebTitleIcon} width="60%" color="var(--color-tertiary-icon)" />
          </div>
          <div className={ErrorPageStyles.message}>
            <Typography variant="h6">{error || t('errorPage.errorMessage')}</Typography>
            <Typography variant="h6">{t('errorPage.errorReason', { error: message })}</Typography>
            <Typography variant="body1">{t('errorPage.reloadingPage', { time: reloadTimer })}</Typography>
          </div>
        </div>
        <Button color="primary" variant="contained" onClick={handleLocation}>
          Try again
        </Button>
      </div>
    </div>
  );
};
