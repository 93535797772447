import { ErrorPage } from '@pages/Error/ErrorPage';
import { t } from 'i18next';
import React, { createContext, useEffect, useMemo, useState } from 'react';

export interface handleSetErrorMessageProps {
  message: string;
  callback?: (...args: unknown[]) => void;
}

export interface ErrorPageContext {
  setErrorMessage: ({ message, callback }: handleSetErrorMessageProps) => void;
}

export const ErrorPageContext = createContext({} as ErrorPageContext);

export interface ErrorPageWrapperProps {
  children: React.ReactNode;
}

export const ErrorPageWrapper: React.FC<ErrorPageWrapperProps> = ({ children }) => {
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    // openID redirect error and error_description
    const error = params.get('error');
    const errorDescription = params.get('error_description');
    if (error) {
      setError(error.replace('+', ' '));
    }
    if (errorDescription) {
      setErrorMessage(errorDescription.replace('+', ' '));
    }
  }, []);

  const handleSetErrorMessage = ({ message, callback }: handleSetErrorMessageProps) => {
    if (message === 'unknown') {
      setErrorMessage(t('alertMessage.unknown'));
    } else {
      setErrorMessage(message);
    }
    callback && callback();
  };

  const contextValue = useMemo(() => {
    return {
      setErrorMessage: handleSetErrorMessage,
    };
  }, [errorMessage]);

  if (error || errorMessage) {
    return <ErrorPage message={errorMessage} error={error} />;
  }

  return <ErrorPageContext.Provider value={contextValue}>{children}</ErrorPageContext.Provider>;
};
