import React from 'react';
import Styles from './Button.module.css';
import { Spinner } from '../Spinner/Spinner';
import { Typography } from '../Typography/Typography';
import { classNames } from '../../utils/cn';

interface ButtonContentProps {
  icon: React.ReactNode;
  isLoading: boolean;
  children: React.ReactNode;
  isTextLeft: boolean;
  iconAlignment: string;
  imgSrc?: string;
  imgAlt?: string;
}

export const ButtonContent = ({
  icon,
  isLoading,
  children,
  isTextLeft,
  iconAlignment,
  imgSrc,
  imgAlt,
}: ButtonContentProps) => {
  if (imgSrc)
    return (
      <div
        className={classNames({
          [Styles.buttonContent]: imgSrc || icon,
          [Styles[iconAlignment]]: imgSrc || icon,
        })}
      >
        <span className={isTextLeft ? Styles.textSpaceBetween : Styles.text}>{children}</span>
        {isLoading ? <Spinner /> : <img src={imgSrc} className={Styles.icon} alt={imgAlt} />}
      </div>
    );
  if (icon) {
    return (
      <div
        className={classNames({
          [Styles.buttonContent]: imgSrc || icon,
          [Styles[iconAlignment]]: imgSrc || icon,
        })}
      >
        <span className={isTextLeft ? Styles.textSpaceBetween : Styles.text}>{children}</span>
        {isLoading ? <Spinner /> : icon}
      </div>
    );
  }
  return (
    <Typography variant="subtitle" type="span" className={Styles.textContainer}>
      {isLoading ? <Spinner /> : children}
    </Typography>
  );
};
