// Determines the search tab categories and order, also determines which assets can be found from search
export const SEARCH_ASSET_TYPES = [
  'all',
  'room',
  'desk',
  'employee',
  'parking',
  'info',
  'marker',
  'zone',
  'bed',
  'wheelchair',
  'medical_equipment',
  'container',
  'emergency',
  'portal',
  'locker',
];

export const VALID_ASSET_TYPES = [
  'all',
  'employee',
  'building',
  'floor',
  'floor_outline',
  'room',
  'floor_hole',
  'portal',
  'locker',
  'info',
  'desk',
  'marker',
  'pressure',
  'temperature',
  'humidity',
  'illuminance',
  'tvoc',
  'co2',
  'ticket',
  'zone',
  'container',
  'emergency',
  'device',
  'voc',
  'voice',
  'parking',
  'bed',
  'wheelchair',
  'medical_equipment',
];

export const EMPLOYEE_SEARCH_FIELDS = ['department', 'email', 'email_public', 'skills', 'title'] as const;
export const tabsAdvancedOptions = [
  'all',
  'room',
  'desk',
  'employee',
  'parking',
  'bed',
  'wheelchair',
  'medical_equipment',
];
