import { CSSProperties } from 'react';
import { createRoot } from 'react-dom/client';
import { Alert, AlertButton, Variant } from './Alert';

interface Root {
  render(children: React.ReactNode): void;
  unmount(): void;
}

const alertContainerId = 'alertContainer';
let root: Root;

interface RenderAlertProps {
  variant: Variant;
  message: string;
  time?: number;
  ariaLabelClose?: string;
  onClose?: () => void;
  button?: AlertButton;
  backgroundColor?: CSSProperties['backgroundColor'];
  foregroundColor?: CSSProperties['color'];
}

export const renderAlert = ({
  variant,
  message,
  time = 5000,
  ariaLabelClose = 'Close',
  onClose,
  button,
  backgroundColor,
  foregroundColor,
}: RenderAlertProps) => {
  const alertContainer = document.getElementById(alertContainerId);
  if (!alertContainer) throw new Error('Create a tag with #alertContainer in your body');

  const alertNode = document.createElement('div');
  const alertNodeId = new Date().getTime() + variant + message;
  alertNode.id = alertNodeId;
  alertNode.style.width = '100%';
  alertContainer.appendChild(alertNode);
  root = createRoot(alertNode);

  time && setTimeout(() => closeAlert(alertNodeId), time);

  const handleCloseButton = () => {
    closeAlert(alertNodeId);
    onClose && onClose();
  };

  root.render(
    <Alert
      variant={variant}
      message={message}
      handleClose={handleCloseButton}
      button={button}
      backgroundColor={backgroundColor || variant}
      foregroundColor={foregroundColor}
      ariaLabelClose={ariaLabelClose}
    />,
  );
  return alertNodeId;
};

export const closeAlert = (id: string) => {
  const alertNode = document.getElementById(id);
  if (alertNode) {
    // umount
    root.unmount();
    alertNode.remove();
  }
};
