import SpinnerStyles from './Spinner.module.css';

export interface SpinnerProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  circleColor?: string;
  spinnerColor?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const Spinner = ({
  circleColor = 'var(--color-primary-icon)',
  spinnerColor = 'var(--color-primary)',
  className = '',
  style = {},
  ...props
}: SpinnerProps) => {
  return (
    <div
      className={`${SpinnerStyles.spinner} ${className}`}
      style={{
        ...style,
        borderColor: circleColor,
        borderTopColor: spinnerColor,
      }}
      {...props}
    ></div>
  );
};
