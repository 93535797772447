/* eslint-disable @typescript-eslint/no-empty-function */
import { ErrorPageWrapper } from '@contexts/error/ErrorPageProvider.js';
import { ScreenSizeWrapper } from '@contexts/screenSize/ScreenSizeProvider.js';
import { SessionProvider } from '@contexts/session/SessionProvider.tsx';
import { SessionMode } from '@contexts/session/types.ts';
import { ThemeProvider } from '@contexts/theme/ThemeProvider.js';
import '@ebDesign/styles/variables.css';
import storage from '@helpers/storage.ts';
import { EbCore } from '@interfaces/core/ebCore';
import { Eb } from '@interfaces/eb/eb';
import { EbMap } from '@interfaces/map/ebMap';
import '@lib/i18n/i18n.ts';
import Router from '@router/Router.tsx';
import { isMobileOnly } from 'react-device-detect';
import { Root, createRoot } from 'react-dom/client';
import './index.css';
import { registerGlobals } from './lib/empathicMe';
import './lib/sdk/client.js';
import './lib/sentry/sentry.js';

let root: Root;
const appRoot = document.getElementById('app-root');

function getInitialSessionDetails() {
  const params = new URLSearchParams(window.location.search);
  const auth = params.get('auth') || storage.read('auth');
  const storedView = params.get('stored_view') || storage.read('storedView');
  const token = params.get('token') || storage.read('token');
  const [href] = location.href;
  const path = location.pathname;
  const search = location.search;
  const mode: SessionMode = auth && storedView ? 'kiosk' : isMobileOnly ? 'mobile' : 'desktop';
  const pathParts = location.pathname.split('/').map(Number);
  const [orgId, locId] = pathParts.slice(1).map((id) => (id > 0 ? String(id) : undefined));
  return { auth, storedView, href, path, search, mode, token, orgId, locId };
}

export type InitialState = ReturnType<typeof getInitialSessionDetails>;
window.renderUI = () => {
  if (!appRoot) {
    throw new Error(`Cannot render UI, app-root element missing `);
  }
  registerGlobals();
  const session = getInitialSessionDetails();
  root = createRoot(appRoot);
  root.render(
    <SessionProvider initalState={session}>
      <ThemeProvider>
        <ScreenSizeWrapper>
          <ErrorPageWrapper>
            <Router mode={session.mode} />
          </ErrorPageWrapper>
        </ScreenSizeWrapper>
      </ThemeProvider>
    </SessionProvider>,
  );
};

declare global {
  interface Window {
    isTeams: boolean;
    eb: Eb;
    eb_core: EbCore;
    eb_map: EbMap;
    renderUI: ({ eb }: { eb: Eb }) => void;
    // Matomo
    _mtm: Array<unknown>;
    _paq: Array<unknown>;
  }
  const eb: Eb;
  const eb_core: EbCore;
  const eb_map: EbMap;
}
