import { Read, Remove, Reset, Write } from '@interfaces/storage';
import { getGivenConsent } from '@lib/cookies/cookies';

const necessaryCookies = ['seenNotifications', 'ebLocalStorageVersion', 'pusherTransportTLS', 'token', 'acks', 'orgID'];

const toString = (value: unknown): string => {
  switch (typeof value) {
    case 'object':
      return JSON.stringify(value);
    case 'number':
    case 'boolean':
    case 'symbol':
    case 'bigint':
      return value.toString();
    case 'string':
      return value;
    case 'undefined':
      throw new Error('Do not store undefined value for a key, delete the key instead using storage.remove!');
    case 'function':
      throw new Error('Do not try to store functions!');
    default:
      throw new Error(`Can't convert type ${typeof value} to String!`);
  }
};

/**
 * Read the value from the Storage.
 * @param key - key name of the value to be fetched from Storage
 * @param defaultValue - value returned if nothing found in Storage
 */
export const read: Read = (key, defaultValue?) => {
  const value = window.localStorage.getItem(key);
  if (!value) return defaultValue ?? null;

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

/**
 * Saves data to Storage.
 * @param key
 * @param value
 * @returns boolean
 * @throws {Error}
 */
export const write: Write = (key, value) => {
  let isWritingAllowed = false;

  if (necessaryCookies.includes(key)) {
    isWritingAllowed = true;
  } else {
    const cookiesConsent = getGivenConsent();
    if (cookiesConsent && cookiesConsent.preferred) {
      isWritingAllowed = true;
    }
  }

  isWritingAllowed && window.localStorage.setItem(key, toString(value));
};

/**
 * Delete key/value pair from Storage.
 * @param key
 */
export const remove: Remove = (key) => {
  window.localStorage.removeItem(key);
};

/**
 * Wipe entire Storage!
 */
export const reset: Reset = () => {
  window.localStorage.clear();
};

export default { read, write, remove, reset };
