import { SVGProps } from 'react';
const SvgEbClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" {...props}>
    <path
      d="m160.86 128 63.75-63.75c8.98-8.98 8.98-23.54 0-32.51s-23.54-8.98-32.51 0l-63.75 63.75L64.6 31.74c-8.98-8.98-23.54-8.98-32.51 0s-8.98 23.54 0 32.51L95.84 128l-63.75 63.75c-8.98 8.98-8.98 23.54 0 32.51 4.49 4.49 10.37 6.73 16.26 6.73s11.77-2.24 16.26-6.73l63.75-63.75 63.75 63.75c4.49 4.49 10.37 6.73 16.26 6.73s11.77-2.24 16.26-6.73c8.98-8.98 8.98-23.54 0-32.51L160.88 128Z"
      fill={props.color}
    />
  </svg>
);
export default SvgEbClose;
