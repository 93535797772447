import { LinkBehavior } from '@components/LinkBehavious';
import { ThemeOptions, alpha, getContrastRatio } from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import merge from 'deepmerge';

export const getCSSVariableValue = (variable: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable);
};

const COMMON_THEME_OPTIONS: ThemeOptions = {
  typography: {
    fontFamily: 'Montserrat',
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
};

const LIGHT_THEME_OPTIONS: ThemeOptions = merge(
  {
    palette: {
      mode: 'light',
      primary: { main: '#143633' },
      // primary: { main: '#DFE3E3' },
      secondary: { main: '#73f9C1' },
      map: {
        main: '#FFFFFF',
        light: alpha('#FFFFFF', 0.5),
        dark: alpha('#FFFFFF', 0.9),
        contrastText: getContrastRatio('#FFFFFF', '#fff') > 4.5 ? '#fff' : '#111',
      },
    },
  },
  COMMON_THEME_OPTIONS,
);

const DARK_THEME_OPTIONS: ThemeOptions = merge(
  {
    palette: {
      mode: 'dark',
      primary: { main: '#73f9C1' },
      secondary: { main: '#ff8262' },
      map: {
        main: '#1a1d1d',
        light: alpha('#1a1d1d', 0.5),
        dark: alpha('#1a1d1d', 0.9),
        contrastText: getContrastRatio('#1a1d1d', '#fff') > 4.5 ? '#fff' : '#111',
      },
    },
  },
  COMMON_THEME_OPTIONS,
);

declare module '@mui/material/styles' {
  interface Palette {
    map: Palette['primary'];
  }

  interface PaletteOptions {
    map?: PaletteOptions['primary'];
  }
}

export { DARK_THEME_OPTIONS, LIGHT_THEME_OPTIONS };
