export const texturePathOverrides = {
  bed: 'icons/ebh-hospital-bed-blue.png',
  bed_hospital: 'icons/ebh-hospital-bed-blue.png',
  wheelchair: 'icons/ebh-hospital-wheelchair-blue.png',
  wheelchair_hospital: 'icons/ebh-hospital-wheelchair-blue.png',
  medical_equipment: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_defibrillator: 'icons/ebh-defibrillator.png',
  medical_equipment_ultrasound_machine: 'icons/ebh-bladder-scanner.png',
  medical_equipment_electrocardiogram_machine: 'icons/ebh-ecg-machine.png',
  medical_equipment_infusion_pump: 'icons/ebh-infusion-pump.png',
  medical_equipment_patient_monitor: 'icons/ebh-medical-monitor.png',
  medical_equipment_urodynamic_testing_system: 'icons/ebh-bladder-scanner.png',
  medical_equipment_mri: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_x_ray_machine: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_ventilator: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_operating_table: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_surgical_lights: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_anesthesia_machine: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_medical_refrigerator: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_autoclave: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_computed_tomography_scanner: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_magnetic_resonance_imaging_machine: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_dialysis_machine: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_blood_bank_refrigerator: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_bilevel_positive_airway_pressure_machine: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_continuous_positive_airway_pressure_machine: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_picture_archiving_and_communication_system: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_electroencephalogram_machine: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_electromyogram_machine: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_positron_emission_tomography_scanner: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_gamma_camera: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_linear_accelerator: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_c_arm_fluoroscope: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_digital_mammography_system: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_endoscopy_tower: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_ophthalmic_laser: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_cardiac_catheterization_lab: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_hyperbaric_oxygen_therapy_chamber: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_lithotripter: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_nuclear_medicine_scanner: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_operating_microscope: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_bone_densitometer: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_surgical_robot_system: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_plasma_sterilizer: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_sleep_apnea_test_system: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_single_photon_emission_computed_tomography_scanner: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_treadmill: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_ultrasound_bone_densitometer: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_vein_finder: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_water_purification_system_for_dialysis: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_holter_monitor_system: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_interventional_radiology_equipment: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_laboratory_information_management_system: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_neuro_navigation_systems: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_phototherapy_lights_for_neonatal_jaundice: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_spine_frame_for_surgery: 'icons/ebh-hospital-medical-equipment-blue.png',
  medical_equipment_transcutaneous_electrical_nerve_stimulation_machine:
    'icons/ebh-hospital-medical-equipment-blue.png',
};

function injectAssetTextureMap() {
  const { texture, model } = eb.map.getResourcePathMap();
  const textureMap = {
    model,
    texture: {
      ...texture,
      ...texturePathOverrides,
    },
  };
  eb.map.setResourcePathMap(textureMap);
}

export { injectAssetTextureMap };
