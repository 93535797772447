function injectCleaningLayer() {
  if (!eb || !eb._d.map) {
    throw new Error('Can not inject a layer before eb and map is initiated');
  }

  const layerTemplateFromInfoLayer = {
    ...eb._d.map.layers_map.info,
    name: 'cleaning',
    title: 'Cleaning',
    asset_types: [],
    search_types: [],
    building_draw_modifiers: 0,
    legend_func: () => {},
    draw_funcs: [],
  };

  eb._d.map.layers.push(layerTemplateFromInfoLayer);
  eb.map.layer.getAllAsMap().cleaning = layerTemplateFromInfoLayer;
}

export { injectCleaningLayer };
