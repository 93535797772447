import { getBrowserName, isBrowser, isIOS } from './utils/browser';
import { classNames } from './utils/cn';
import {
  colorVarToRgbString,
  getDarkerOrLighterColor,
  getFontColor,
  getTransparentThemeColor,
  hexToObj,
  hexToRgba,
  hslaToHex,
  hslaToObj,
  rgbaToHsla,
  rgbaToObj,
} from './utils/color';

export const util = {
  hexToObj,
  hslaToObj,
  hexToRgba,
  hslaToHex,
  rgbaToHsla,
  rgbaToObj,
  getDarkerOrLighterColor,
  getBrowserName,
  getFontColor,
  colorVarToRgbString,
  isBrowser,
  classNames,
  isIOS,
  getTransparentThemeColor,
};

export { Styled } from './components/Styled/Styled';
export { withStyleWrapper } from './hoc/withStyle';
